import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Steps } from "primereact/steps";
import WizardLogical, { WizardLogicalType } from "./WizardLogical";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import StaticHeader from "../static-header/StaticHeader";
import Search from "../steps/Search";
import Appointments from "../steps/Appointments";
import PatientInfo from "../steps/PatientInfo";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import ErrorElement from "../error-element/ErrorElement";
import Disclaimer from "../disclaimer/Disclaimer";
import Payment from "../steps/Payment";
import ProcessingPaymentDialog from "../processing-payment/ProcessingPaymentDialog";
import Footer from "../footer/Footer";
import NotInFunctionPopUp from "../disclaimer/NotInFunctionPopUp";

export default function Wizard() {
  const {
    state,
    dispatch,
    items,
    appointmentsSearch,
    setAppointmentsSearch,
    searchAppointment,
    clearFirstStep,
    invalidFields,
    setInvalidFields,
    isNextButtonDisabled,
    captchaRef,
    onVerify,
    getLocationsDoctorsForSpecialtyAndProvider,
    getProcedures,
    sessionCodeRef,
    firstToSecondStepAction,
    secondToFirstStepAction,
    secondToThirdStepAction,
    thirdToSecondStepAction,
    thirdToFourthStepAction,
    fourthToThirdStepAction,
    zeroToFirstStepAction,
  }: WizardLogicalType = WizardLogical();

  const renderStep = () => {
    switch (state.step) {
      case 0:
        return (
          <Search
            appointmentsSearch={appointmentsSearch}
            setAppointmentsSearch={setAppointmentsSearch}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            state={state}
            dispatch={dispatch}
            getLocationsDoctorsForSpecialtyAndProvider={getLocationsDoctorsForSpecialtyAndProvider}
            getProcedures={getProcedures}
            sessionCodeRef={sessionCodeRef}
          />
        );
      case 1:
        return <Appointments state={state} dispatch={dispatch} />;
      case 2:
        return <PatientInfo state={state} dispatch={dispatch} invalidFields={invalidFields} setInvalidFields={setInvalidFields} captchaRef={captchaRef} onVerify={onVerify} />;
      case 3:
        return <Payment state={state} dispatch={dispatch} sessionCodeRef={sessionCodeRef} />;
      default:
        return (
          <Search
            appointmentsSearch={appointmentsSearch}
            setAppointmentsSearch={setAppointmentsSearch}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            state={state}
            dispatch={dispatch}
            getLocationsDoctorsForSpecialtyAndProvider={getLocationsDoctorsForSpecialtyAndProvider}
            getProcedures={getProcedures}
            sessionCodeRef={sessionCodeRef}
          />
        );
    }
  };

  const renderClearLabel = () => {
    switch (state.step) {
      case 0:
        return Labels.LABEL_OCISTI_POLJA;
      case 1:
        return Labels.LABEL_PONISTI_IZBOR;
      case 2:
        return Labels.LABEL_OCISTI_POLJA;
      default:
        return Labels.LABEL_OCISTI_POLJA;
    }
  };

  const renderNextStepLabel = () => {
    switch (state.step) {
      case 0:
        return Labels.LABEL_PRETRAZI;
      case 1:
        return Labels.LABEL_NASTAVI;
      case 2:
        return Labels.LABEL_NASTAVI;
      case 3:
        return Labels.LABEL_ZAKAZI;
      default:
        return Labels.LABEL_PRETRAZI;
    }
  };

  const renderNextStepAction = () => {
    switch (state.step) {
      case 0:
        return firstToSecondStepAction();
      case 1:
        return secondToThirdStepAction();
      case 2:
        return thirdToFourthStepAction();
      default:
        return searchAppointment();
    }
  };

  const renderBackStepAction = () => {
    switch (state.step) {
      case 1:
        return secondToFirstStepAction();
      case 2:
        return thirdToSecondStepAction();
      case 3:
        return fourthToThirdStepAction();
      default:
        return firstToSecondStepAction();
    }
  };

  const renderClearAction = () => {
    switch (state.step) {
      case 0:
        return clearFirstStep();
      case 1:
        return dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "appointment", value: undefined } });
      case 2:
        return dispatch({
          type: actions.APPOINTMENT_CHANGE,
          appointment: {
            ...state.appointment!,
            fieldName: "appointment",
            value: { ...state?.appointment?.appointment, patientFirstName: null, patientLastName: null, patientPhoneNumber: null, patientEmail: null, patientDateOfBirth: null, patientAddress: null },
          },
        });
      default:
        return clearFirstStep();
    }
  };

  return (
    <>
      <StaticHeader dispatch={dispatch} />
      {state?.warningText?.length && <div className="warning-text">{state?.warningText}</div>}
      {state?.serviceAvailability ? (
        <div
          className={`wizard flex flex-column ${state?.step !== 3 ? "justify-content-between" : "justify-content-start"} ${
            state?.step === 0 || state?.step === 2 ? "image-1-background" : "image-2-background"
          }`}>
          <div className="wizard-nav flex flex-row justify-content-center py-4">
            <div className="col-12 sm:col-8 md:col-6 relative">
              <Steps className="wizard-nav__steps" model={items} activeIndex={state.step} readOnly={false} />
              <div className="text-center pt-4 sm:pt-0">
                {state.step > 0 && (
                  <Button
                    className="p-button-primary nav-btn nav-btn-back mr-3 sm:mr-0"
                    icon="pi pi-chevron-left"
                    disabled={state.step === 0}
                    onClick={() => {
                      renderBackStepAction();
                    }}
                  />
                )}
                {state.step < 3 && (
                  <Button
                    className="p-button-primary nav-btn nav-btn-forward"
                    icon="pi pi-chevron-right"
                    disabled={state?.step === 0 && state?.appointment?.length === 0 ? true : !isNextButtonDisabled(state.step)}
                    onClick={() => {
                      renderNextStepAction();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`flex ${state?.step !== 3 ? "flex-row flex-wrap justify-content-between" : "flex-column align-items-center justify-content-between content-wrapper-payment"} content-wrapper`}>
            {renderStep()}
          </div>
          {state?.step !== 3 && (
            <div className="wizard-footer flex flex-row justify-content-center py-4">
              {state?.step !== 3 && <Button className="p-button-outlined mr-5 border-radius-0" label={renderClearLabel()} onClick={() => renderClearAction()} />}
              <Button
                label={renderNextStepLabel()}
                onClick={() => {
                  renderNextStepAction();
                }}
                className="border-radius-0"
                disabled={!isNextButtonDisabled(state.step) || state?.appointmentsLoading}
                icon={`${state?.appointmentsLoading ? "pi pi-spin pi-spinner mr-2" : ""}`}
              />
            </div>
          )}
        </div>
      ) : (
        <ErrorElement />
      )}
      <Footer />
      <Disclaimer state={state} dispatch={dispatch} zeroToFirstStepAction={zeroToFirstStepAction} />
      <ConfirmPopup />
      <ProcessingPaymentDialog state={state} />
      <NotInFunctionPopUp state={state} dispatch={dispatch} />
    </>
  );
}
