import { Image } from "primereact/image";
import Images from "../../infrastructure/system/Images";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
// @ts-ignore
import UsloviPlacanja from "../../../src/infrastructure/documents/uslovi.pdf";
// @ts-ignore
import PravilaPrivatnosti from "../../../src/infrastructure/documents/pravila.pdf";

export default function Footer() {
  return (
    <div className="footer bg-red py-4 flex flex-row justify-content-center no-print">
      <div className="flex flex-row flex-wrap justify-content-center w-12 lg:w-10">
        <div className="col-12 xl:col-10">
          <Image src={Images.EUROMEDIK_LOGO_GREEN} />
        </div>
        <div className="col-12 xl:col-10">
          <ul className="footer-menu-list mt-0">
            <li>
              <a href="https://euromedic.rs/" target="_blank" rel="noreferrer">
                Naslovna
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/usluge/" target="_blank" rel="noreferrer">
                Usluge
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/lokacije/" target="_blank" rel="noreferrer">
                Lokacije
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/cenovnik/" target="_blank" rel="noreferrer">
                Cenovnik
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/paketi-pregleda/" target="_blank" rel="noreferrer">
                Paketi pregleda
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/pregledi/" target="_blank" rel="noreferrer">
                O pregledima
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/pregledi/karijera/" target="_blank" rel="noreferrer">
                Karijera
              </a>
            </li>
            <li>
              <a href="https://euromedic.rs/pregledi/galerija/" target="_blank" rel="noreferrer">
                Galerija
              </a>
            </li>
            <li>
              <a href={UsloviPlacanja} target="_blank" rel="noreferrer">
                Uslovi plaćanja
              </a>
            </li>
            <li>
              <a href={PravilaPrivatnosti} target="_blank" rel="noreferrer">
                Pravila privatnosti
              </a>
            </li>
            <li>
              <a href="/contact" target="_blank" rel="noreferrer">
                Kontakt
              </a>
            </li>
          </ul>
          <div className="footer-logos block lg:flex flex-row justify-content-between align-items-center py-3">
            <div className="flex flex-wrap align-items-center cards">
              <Image src={Images.VISA_ICON} className="mr-2" />
              <Image src={Images.DINACARD_ICON} className="mr-2" />
              <Image src={Images.MAESTRO_ICON} className="mr-2" />
              <Image src={Images.MASTER_ICON} className="mr-2" />
              <Image src={Images.AMEX_ICON} />
            </div>
            <div className="flex flex-wrap align-items-start mt-3 lg:mt-0 banks-procesors">
              <a href="https://www.allsecure.rs/" target="_blank" rel="noreferrer">
                <Image src={Images.ALLSECURE_ICON} className="allsecure-icon mr-2" />
              </a>
              <a href="https://www.bancaintesa.rs/" target="_blank" rel="noreferrer">
                <Image src={Images.BANCA_INTESA_ICON} className="mr-2 banca-intesa-icon" />
              </a>
              <a href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html" target="_blank" rel="noreferrer">
                <Image src={Images.VISA_SECURE} className="mr-2" />
              </a>
              <a href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html" target="_blank" rel="noreferrer">
                <Image src={Images.MASTERCARD_ID_CHECK_ICON} className="mastercard-check-id-icon" />
              </a>
            </div>
          </div>
          <div className="white currency-statement pt-3">{Labels.LABEL_CURRENCY_STATEMENT}</div>
          <div className="white copyrights pt-3">{`${Labels.LABEL_COPYRIGHTS} ${new Date().getFullYear()}`}</div>
        </div>
      </div>
    </div>
  );
}
