import { Skeleton } from "primereact/skeleton";

interface SkeletonAppointmentsProps {
  widthTitle?: string;
  heightTitle?: string;
  size?: number;
}

export default function SkeletonAppointments(props: SkeletonAppointmentsProps) {
  const { widthTitle, heightTitle, size } = props;

  return (
    <>
      <Skeleton className="p-2 ml-5 mb-3" height={heightTitle ?? "2rem"} width={widthTitle ?? "20rem"} />
      {
        // @ts-ignore
        [...Array(size ?? 2).keys()].map((j: number) => (
          <Skeleton key={j} height="100px" className="col-12 mt-3" />
        ))
      }
    </>
  );
}
