import { Route, Routes } from "react-router-dom";
import Wizard from "./wizard/Wizard";
import SuccessPage from "./success-page/SuccessPage";
import TransactionCanceled from "./transaction-error/TransactionCanceled";
import TransactionError from "./transaction-error/TransactionError";
import Contact from "./contact/Contact";

export default function Content() {
  return (
    <Routes>
      <Route path="/" element={<Wizard />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/canceled" element={<TransactionCanceled />} />
      <Route path="/error" element={<TransactionError />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}
