import moment from "moment";
import { addLocale, locale } from "primereact/api";
import { EffectCallback, useEffect } from "react";
import MessageType from "./MessageType";

const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

const axiosConfig = (params?: Object) => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const dateAndTime = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_TIME_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const date = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const time = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format("h:mm");
  } else {
    return "Lose zadat datum";
  }
};

const formatDate = (dateValue?: Date, format = DATE_TIME_FORMAT_3) => (dateValue ? moment(new Date(dateValue)).format(format) : undefined);

const localEmailRegexp = RegExp(
  //eslint-disable-next-line
  /([a-z0-9!#$%&'*+/=?^_`{|}~-￿-]+|"([a-z0-9!#$%&'*.(),<>\[\]:;  @+/=?^_`{|}~-￿-]|\\\\|\\\")+")(\.([a-z0-9!#$%&'*+/=?^_`{|}~-￿-]+|"([a-z0-9!#$%&'*.(),<>\[\]:;  @+/=?^_`{|}~-￿-]|\\\\|\\\")+"))*/
);
const domainEmailRegexp = RegExp(
  /([a-z-￿0-9!#$%&'*+/=?^_`{|}~]-*)*[a-z-￿0-9!#$%&'*+/=?^_`{|}~]+(\.([a-z-￿0-9!#$%&'*+/=?^_`{|}~]-*)*[a-z-￿0-9!#$%&'*+/=?^_`{|}~]+)*|\[(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\]/
);
const validateEmail = (email: string) => {
  let indexOfDomain: number = email.lastIndexOf("@");
  if (indexOfDomain < 0) {
    return false;
  }
  let localPart: string = email.substring(0, indexOfDomain);
  let domainPart: string = email.substring(indexOfDomain + 1);
  if (!localEmailRegexp.test(localPart).valueOf()) {
    return false;
  }
  if (domainPart.endsWith(".")) {
    return false;
  }
  if (!domainEmailRegexp.test(domainPart).valueOf()) {
    return false;
  }
  return true;
};

const validateStringEmpty = (str: string | undefined) => !str || str.trim() === "";

const isSifraIncluded = (itemList: any, sifra: any) => itemList?.some((x: any) => x === sifra);

const skeletonTimeout = (setLoadingState: React.Dispatch<React.SetStateAction<boolean>>, startTime: moment.Moment, miliseconds: number = 500) => {
  if (moment(new Date()).diff(startTime) < miliseconds) {
    setTimeout(() => {
      setLoadingState(false);
    }, miliseconds);
  } else {
    setLoadingState(false);
  }
};

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const DATE_TIME_FORMAT_FULL = "DD.MM.YYYY. HH:mm:ss";
const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_FORMAT2 = "YYYY-MM-DD";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const DATE_TIME_FORMAT_2 = "YYYY-MM-DD HH:mm:ss";
const DATE_TIME_FORMAT_3 = "YYYY-MM-DDTHH:mm:ss";
const TIME_FORMAT = "HH:mm";
const STEP_MINUTE = 5;
const CHECKING_SERVICE_AVAILABILITY_INTERVAL = 10000;
const MESSAGE_DURATION_IN_MILLISECONDS = 4000;

const checkEmpty = (variable: any) => {
  if (variable === undefined || variable === null || variable === "") {
    return true;
  }
  return false;
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error.response.data.errors) {
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const setInvalid = (setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>, field: string, value: any) => {
  if (!value || value === "") {
    setInvalidFields((prev: any) => ({ ...prev, [field]: true }));
  }
  if (value && value !== "") {
    setInvalidFields((prev: any) => ({ ...prev, [field]: false }));
  }
};

const createCurrentDateWithTime = (timeHH_MM: string) => {
  const currentDate = new Date();
  currentDate.setHours(Number(timeHH_MM.substring(0, 2)));
  currentDate.setMinutes(Number(timeHH_MM.substring(3, 5)));
  return currentDate;
};

const getDateDivisibleBy5 = (date: Date = new Date()) => new Date(date?.setMinutes(Math.ceil(date?.getMinutes() / 5) * 5));

// eslint-disable-next-line
const validPhoneNumberRegex: RegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{3,4}$/;
const isPhoneNumberFormatValid = (phoneNumber: string | undefined) => {
  if (phoneNumber?.match(validPhoneNumberRegex)) {
    return true;
  }
  return false;
};

/**
 * metoda za formatiranje brojeva,
 * pored samog broja kao argumente prihvata min i max fraction digits vezano za decimale
 * source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
const numberFormat = (value: number, minimumFractionDigits: number, maximumFractionDigits: number) =>
  new Intl.NumberFormat("sr-RS", {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);

const setCaptchaLang = (lang: string) => {
  const container = document.getElementById("captcha_container");
  const iframeGoogleCaptcha = container?.querySelector("iframe");
  const actualLang = iframeGoogleCaptcha
    ?.getAttribute("src")!
    .match(/hl=(.*?)&/)!
    .pop();
  if (actualLang !== lang) {
    iframeGoogleCaptcha?.setAttribute("src", iframeGoogleCaptcha?.getAttribute("src")!.replace(/hl=(.*?)&/, "hl=" + lang + "&"));
  }
};

const addSrLocal = () => {
  addLocale("sr", {
    firstDayOfWeek: 1,
    dayNames: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    dayNamesShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
    dayNamesMin: ["N", "P", "U", "S ", "Č", "P ", "S"],
    monthNames: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
    weekHeader: "Sedmica",
    clear: "Poništi",
    today: "Danas",
  });
  addLocale("sr-cyrilic", {
    firstDayOfWeek: 1,
    dayNames: ["Недеља", "Понедељак", "Уторак", "Среда", "Четвртак", "Петак", "Субота"],
    dayNamesShort: ["Нед", "Пон", "Уто", "Сре", "Чет", "Пет", "Суб"],
    dayNamesMin: ["Н", "П", "У", "С ", "Ч", "П ", "С"],
    monthNames: ["Јануар", "Фебруар", "Март", "Април", "Мај", "Јун", "Јул", "Август", "Септембар", "Октобар", "Новембар", "Децембар"],
    monthNamesShort: ["Јан", "Феб", "Мар", "Апр", "Мај", "Јун", "Јул", "Авг", "Сеп", "Окт", "Нов", "Дец"],
    weekHeader: "Седмица",
    clear: "Поништи",
    today: "Данас",
  });
  locale("sr");
  locale("sr-cyrilic");
};

export {
  useEffectOnce,
  dateAndTime,
  date,
  time,
  axiosConfig,
  CALENDAR_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT_FULL,
  DATE_FORMAT2,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  TIME_FORMAT,
  STEP_MINUTE,
  CHECKING_SERVICE_AVAILABILITY_INTERVAL,
  MESSAGE_DURATION_IN_MILLISECONDS,
  validateEmail,
  validateStringEmpty,
  isSifraIncluded,
  skeletonTimeout,
  checkEmpty,
  handleAxiosCallError,
  formatDate,
  setInvalid,
  createCurrentDateWithTime,
  getDateDivisibleBy5,
  isPhoneNumberFormatValid,
  numberFormat,
  setCaptchaLang,
  addSrLocal,
};
