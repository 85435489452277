import { Accordion, AccordionTab } from "primereact/accordion";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";

interface SpecializationPropsType {
  additionalClass?: string;
}

export default function Specialization(props: SpecializationPropsType) {
  const { additionalClass } = props;

  return (
    <div className={`specializations-layout col-12 xl:col-3 ${additionalClass ?? ""}`}>
      <div className="green-box text-white px-2 py-3 align-items-center">
        <ul className="pl-3">
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_1}</span>
          </li>
          <li>
            <span className="font-bold">
              {Labels.LABEL_SPECIALIZATIONS_2} <br /> {Labels.LABEL_SPECIALIZATIONS_3}
            </span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_4}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_5}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_6}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_7}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_8}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_9}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_10}</span>
          </li>
          <li>
            <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_11}</span>
          </li>
        </ul>
      </div>
      <Accordion collapseIcon="pi pi-chevron-up" expandIcon="pi pi-chevron-down" className="m-auto">
        <AccordionTab headerTemplate={<h1>Euromedik usluge</h1>} headerClassName="uppercase font-bold">
          <ul className="pl-3 mt-0">
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_1}</span>
            </li>
            <li>
              <span className="font-bold">
                {Labels.LABEL_SPECIALIZATIONS_2} <br /> {Labels.LABEL_SPECIALIZATIONS_3}
              </span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_4}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_5}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_6}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_7}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_8}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_9}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_10}</span>
            </li>
            <li>
              <span className="font-bold">{Labels.LABEL_SPECIALIZATIONS_11}</span>
            </li>
          </ul>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
