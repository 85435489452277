import React from 'react';
import Scheduler from "./components/Scheduler";

function App() {
  return (
    <Scheduler />
  );
}

export default App;
