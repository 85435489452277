import axios, { AxiosResponse } from "axios";
import { MenuItem } from "primereact/menuitem";
import React, { RefObject, useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import InitialState, { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import reducer from "../../infrastructure/system/hooks/wizard-reducer/reducer";
import {
  checkEmpty,
  CHECKING_SERVICE_AVAILABILITY_INTERVAL,
  createCurrentDateWithTime,
  DATE_FORMAT2,
  formatDate,
  handleAxiosCallError,
  isPhoneNumberFormatValid,
  TIME_FORMAT,
  useEffectOnce,
  validateEmail,
} from "../../infrastructure/Utils";
import Endpoint from "../../infrastructure/system/Endpoint";
import { AppContext } from "../../Store";
import WizardController, { WizardControllerType } from "../../controllers/WizardController";
import SchedulerSearchCreateDto from "../../model/SchedulerSearchCreateDto";
import MessageType from "../../infrastructure/MessageType";
import AppointmentReservationCreateDto from "../../model/AppointmentReservationCreateDto";
import moment from "moment";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import { v4 as uuidv4 } from "uuid";
import WebsocketMessageReadDto from "../../model/WebsocketMessageReadDto";
import AllSecureTransactionStatus from "../../model/AllSecureTransactionStatus";
import LocationReadDto from "../../model/LocationReadDto";

export interface WizardLogicalType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
  items: MenuItem[];
  appointmentsSearch: SchedulerSearchCreateDto | undefined;
  setAppointmentsSearch: React.Dispatch<React.SetStateAction<any>>;
  searchAppointment: () => void;
  clearFirstStep: () => void;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  isNextButtonDisabled: (step: number) => boolean | undefined;
  reserveAppointment: (appointment: AppointmentReservationCreateDto, transactionToken: string, dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>, sessionCode: string) => void;
  checkServiceAvailability: () => void;
  captchaRef: React.MutableRefObject<any>;
  onVerify: (recaptchaResponse: any) => void;
  getLocationsDoctorsForSpecialtyAndProvider: (specialtyID: number, procedureID: number) => void;
  validatePatientStep: () => void;
  getProcedures: (specialtyID: number) => void;
  sessionCodeRef: RefObject<string>;
  zeroToFirstStepAction: () => void;
  firstToSecondStepAction: () => void;
  secondToFirstStepAction: () => void;
  secondToThirdStepAction: () => void;
  thirdToSecondStepAction: () => void;
  thirdToFourthStepAction: () => void;
  fourthToThirdStepAction: () => void;
}

export default function WizardLogical() {
  const { showMessage } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, InitialState);
  const [appointmentsSearch, setAppointmentsSearch] = useState<SchedulerSearchCreateDto | undefined>();
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const captchaRef = useRef(null);
  const feInfoRef = useRef<any>(undefined);
  const navigate = useNavigate();
  const {
    axiosGetSpecialisations,
    axiosGetNonReservedAppointments,
    axiosGetServiceAvailability,
    axiosGetProceduresForSpecialtyAndProvider,
    axiosGetDoctorsForSpecialtyProviderAndProcedure,
    axiosGetLocationsForProcedureAndProvider,
    axiosAllSecureReserveAppointment,
    axiosGetWizardStep,
  }: WizardControllerType = WizardController();
  let sessionCodeRef = useRef<string>(uuidv4());

  const isNextButtonDisabled = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return (
            !checkEmpty(appointmentsSearch?.fromTime) &&
            !checkEmpty(appointmentsSearch?.toTime) &&
            !checkEmpty(appointmentsSearch?.fromDate) &&
            appointmentsSearch?.specialtyIdList?.length !== 0 &&
            !checkEmpty(appointmentsSearch?.specialtyIdList?.length) &&
            !checkEmpty(state?.appointment?.procedure)
          );
        case 1:
          return state?.appointment?.appointment !== undefined;
        case 2:
          return (
            !checkEmpty(state?.appointment?.appointment?.patientFirstName) &&
            !checkEmpty(state?.appointment?.appointment?.patientLastName) &&
            !checkEmpty(state?.appointment?.appointment?.patientPhoneNumber) &&
            !checkEmpty(state?.appointment?.appointment?.patientEmail) &&
            state?.privacyPolicy &&
            state?.captchaSuccess
          );
        case 3:
          return true;
        default:
          return false;
      }
    },
    [
      appointmentsSearch?.fromDate,
      appointmentsSearch?.fromTime,
      appointmentsSearch?.specialtyIdList?.length,
      appointmentsSearch?.toTime,
      state?.appointment?.appointment,
      state?.appointment?.procedure,
      state?.captchaSuccess,
      state?.privacyPolicy,
    ]
  );

  const items = [
    {
      label: Labels.LABEL_PRETRAGA,
      className:
        !checkEmpty(appointmentsSearch?.fromTime) && !checkEmpty(appointmentsSearch?.toTime) && !checkEmpty(appointmentsSearch?.fromDate) && !checkEmpty(appointmentsSearch?.specialtyIdList?.length)
          ? "visited"
          : "",
    },
    {
      label: Labels.LABEL_TERMINI,
      className: state?.appointment?.appointment !== undefined ? "visited" : "",
    },
    {
      label: Labels.LABEL_PACIJENT,
      className:
        !checkEmpty(state?.appointment?.appointment?.patientFirstName) &&
        !checkEmpty(state?.appointment?.appointment?.patientLastName) &&
        !checkEmpty(state?.appointment?.appointment?.patientPhoneNumber) &&
        !checkEmpty(state?.appointment?.appointment?.patientEmail)
          ? "visited"
          : "",
    },
    {
      label: Labels.LABEL_PAYMENT,
    },
  ];

  let websocketUUID: string | undefined = undefined;
  let newSocket: WebSocket | undefined = undefined;

  useEffectOnce(() => {
    if (!checkEmpty(localStorage.getItem("reservationUUID"))) {
      dispatch({ type: actions.PAYMENT_PROCESSING, value: true });
      openSocketAndAddListeners();
    }
    const baseStartDate = new Date();
    baseStartDate.setHours(7, 0);
    const baseEndDate = new Date();
    baseEndDate.setHours(20, 0);

    if (!Boolean(localStorage.getItem("acceptedDisclaimer"))) {
      dispatch({ type: actions.SHOW_DISCLAIMER, value: true });
      dispatch({ type: actions.SHOW_DISCLAIMER_FOOTER, value: true });
    }
    setAppointmentsSearch({
      ...appointmentsSearch!,
      providerList: [{ providerId: Number(process.env.REACT_APP_PROVIDER_ID), locationIdList: null, doctorList: null, procedureId: undefined }],
      fromDate: formatDate(new Date(), DATE_FORMAT2),
      fromTime: formatDate(baseStartDate, TIME_FORMAT),
      toTime: formatDate(baseEndDate, TIME_FORMAT),
      limitPerProvider: 20,
    });

    // brisemo token i informacije o uplati
    return () => {
      localStorage.removeItem("nadjimed-auth");
      localStorage.removeItem("paymentInfo");
      if (newSocket) newSocket.close();
    };
  });

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;
    if (!state.serviceAvailability) {
      interval = setInterval(() => {
        checkServiceAvailability();
      }, CHECKING_SERVICE_AVAILABILITY_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.serviceAvailability]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state?.step]);

  const openSocketAndAddListeners = () => {
    websocketUUID = JSON.parse(localStorage.getItem("reservationUUID")!);
    newSocket = new WebSocket(Endpoint.ALL_SECURE_WEBSOCKET + websocketUUID);
    // Set up event listeners for the WebSocket
    newSocket.addEventListener("open", () => {
      //console.log("WebSocket connection opened");
    });

    newSocket.addEventListener("message", (event: MessageEvent) => {
      let payload: WebsocketMessageReadDto = JSON.parse(event.data);
      //console.log("WebSocket message received:", event.data);
      if (payload.success) {
        const existingPaymentInfo = JSON.parse(localStorage.getItem("paymentInfo")!);
        let updatedPaymentInfo = { ...existingPaymentInfo, ...payload };
        localStorage.setItem("paymentInfo", JSON.stringify(updatedPaymentInfo));
        navigate("/success");
      }
      if (!payload.success) {
        const existingPaymentInfo = JSON.parse(localStorage.getItem("paymentInfo")!);
        let updatedPaymentInfo = { ...existingPaymentInfo, ...payload };
        localStorage.setItem("paymentInfo", JSON.stringify(updatedPaymentInfo));
        navigate("/error");
      }
    });

    newSocket.addEventListener("close", () => {
      //console.log("WebSocket connection closed");
      localStorage.removeItem("reservationUUID");
    });
  };

  const fetchFilterData = (token?: string) => {
    dispatch({ type: actions.FILTER_DATA_LOADING, value: true });
    axiosGetSpecialisations(Number(process.env.REACT_APP_SHEDULER_ID), Number(process.env.REACT_APP_PROVIDER_ID), sessionCodeRef?.current)
      .then((response: AxiosResponse) => {
        dispatch({ type: actions.SPECIALISATIONS, value: response.data.data });
      })
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          handleAxiosCallError(showMessage, e);
        }
      })
      .finally(() => {
        dispatch({ type: actions.FILTER_DATA_LOADING, value: false });
      });
  };

  const validateInput = (appointmentsSearch: SchedulerSearchCreateDto | undefined) => {
    let isInvalid = false;

    if (appointmentsSearch?.specialtyIdList?.length === 0 || appointmentsSearch?.specialtyIdList?.length === undefined) {
      setInvalidFields((prev) => ({ ...prev, specialtyIdList: true }));
      isInvalid = true;
    }
    if (checkEmpty(appointmentsSearch?.fromDate)) {
      setInvalidFields((prev) => ({ ...prev, fromDate: true }));
      isInvalid = true;
    }
    if (checkEmpty(appointmentsSearch?.fromTime)) {
      setInvalidFields((prev) => ({ ...prev, fromTime: true }));
      isInvalid = true;
    }
    if (checkEmpty(appointmentsSearch?.toTime)) {
      setInvalidFields((prev) => ({ ...prev, toTime: true }));
      isInvalid = true;
    }

    if (appointmentsSearch?.toDate && !moment(appointmentsSearch.toDate).isSameOrAfter(moment(appointmentsSearch?.fromDate), "days")) {
      setInvalidFields((prev) => ({ ...prev, toDate: true }));
      isInvalid = true;
    }

    if (!checkEmpty(appointmentsSearch?.toTime) && !checkEmpty(appointmentsSearch?.fromTime)) {
      let currentDate = createCurrentDateWithTime(appointmentsSearch?.toTime ?? "");
      let minDate = createCurrentDateWithTime(appointmentsSearch?.fromTime ?? "");
      if (minDate && currentDate < minDate) {
        setInvalidFields((prev) => ({ ...prev, toTimeInvalid: true }));
        isInvalid = true;
      }
    }

    return !isInvalid;
  };

  const searchAppointment = () => {
    if (!validateInput(appointmentsSearch)) {
      return;
    }

    let appoinmentSearchFreshProviderList = appointmentsSearch?.providerList?.map((obj) => {
      return {
        ...obj,
        providerId: Number(process.env.REACT_APP_PROVIDER_ID),
        // ukoliko nije odabrana lokacija, saljemo sve za pretragu
        locationIdList: obj.locationIdList?.length ? obj.locationIdList : state?.locations?.map((location: LocationReadDto) => location.id),
        procedureId: state?.appointment?.procedure?.id,
      };
    });
    let appointmentSearchFresh: SchedulerSearchCreateDto = { ...appointmentsSearch!, providerList: appoinmentSearchFreshProviderList! };

    if (appointmentSearchFresh) {
      dispatch({ type: actions.APPOINTMENTS_LOADING, value: true });
      axiosGetNonReservedAppointments(appointmentSearchFresh, Number(process.env.REACT_APP_SHEDULER_ID), sessionCodeRef?.current)
        .then((response: AxiosResponse) => {
          if (response.data?.data[0]?.specialtyList.length === 0 || response.data?.data.length === 0) {
            showMessage(MessageType.INFO, Labels.LABEL_NO_AVAILABLE_APPOINTMENTS);
          } else {
            dispatch({ type: actions.INCREMENT_STEP });
            dispatch({ type: actions.APPOINTMENTS, value: response.data.data });
          }
        })
        .catch((e) => {
          if (!e.response) {
            dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
          } else {
            handleAxiosCallError(showMessage, e);
          }
        })
        .finally(() => {
          dispatch({ type: actions.APPOINTMENTS_LOADING, value: false });
        });
    }
  };

  const clearFirstStep = () => {
    setAppointmentsSearch({
      ...appointmentsSearch!,
      providerList: [{ providerId: Number(process.env.REACT_APP_PROVIDER_ID), locationIdList: [], doctorList: [], procedureId: undefined }],
      limitPerProvider: 20,
      fromDate: undefined,
      toDate: undefined,
      fromTime: undefined,
      toTime: undefined,
      specialtyIdList: undefined,
      specialtyIdListFull: undefined,
    });
  };

  const validatePatientInformation = () => {
    let isInvalid = false;

    if (checkEmpty(state?.appointment?.appointment?.patientFirstName)) {
      setInvalidFields((prev) => ({ ...prev, patientFirstName: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.appointment?.appointment?.patientLastName)) {
      setInvalidFields((prev) => ({ ...prev, patientLastName: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.appointment?.appointment?.patientPhoneNumber) || !isPhoneNumberFormatValid(state?.appointment?.appointment?.patientPhoneNumber)) {
      setInvalidFields((prev) => ({ ...prev, patientPhoneNumber: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.appointment?.appointment?.patientEmail) || !validateEmail(state?.appointment?.appointment?.patientEmail)) {
      setInvalidFields((prev) => ({ ...prev, patientEmail: true }));
      isInvalid = true;
    }
    if (!state?.privacyPolicy) {
      setInvalidFields((prev) => ({ ...prev, patientPrivacyPolicy: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const reserveAppointment = (appointment: AppointmentReservationCreateDto, transactionToken: string, dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>, sessionCode: string) => {
    let reserveAppointment = { ...appointment, transactionToken: transactionToken, uuid: uuidv4() };
    localStorage.setItem("reservationUUID", JSON.stringify(reserveAppointment.uuid));
    axiosAllSecureReserveAppointment(reserveAppointment, sessionCode)
      .then((response: AxiosResponse) => {
        const reservationResponseData = response.data.data;
        feInfoRef.current = appointment.frontendInformation;
        localStorage.setItem("paymentInfo", JSON.stringify(appointment.frontendInformation));
        if (reservationResponseData.status === AllSecureTransactionStatus.IN_PROGRESS && !checkEmpty(reservationResponseData.redirectUrl)) {
          window.location.replace(response.data.data.redirectUrl);
        }
        // ovaj blok se izvrsava kada nema redirekcije na 3Dsecure
        if (reservationResponseData.status === AllSecureTransactionStatus.SUCCESS && checkEmpty(reservationResponseData.redirectUrl)) {
          openSocketAndAddListeners();
        }
        if (reservationResponseData.status === AllSecureTransactionStatus.ERROR) {
          navigate("/error");
        }
      })
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.PAYMENT_PROCESSING, value: false });
          localStorage.removeItem("paymentInfo");
          localStorage.removeItem("reservationUUID");
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          dispatch({ type: actions.PAYMENT_PROCESSING, value: false });
          localStorage.removeItem("paymentInfo");
          localStorage.removeItem("reservationUUID");
          handleAxiosCallError(showMessage, e);
        }
      });
  };

  const checkServiceAvailability = () => {
    axiosGetServiceAvailability()
      .then(() => {
        //console.log("service available!");
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: true });
        if (state?.specialtiesList.length === 0 || state?.doctors.length === 0 || state?.locations?.length === 0) {
          fetchFilterData();
        }
      })
      .catch(() => {
        //console.log("service unavailable!");
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      });
  };

  const onVerify = (recaptchaResponse: string) => {
    if (!checkEmpty(recaptchaResponse)) dispatch({ type: actions.CAPTCHA_SUCCESS, value: true });
  };

  const checkIfTimeoutNeeded = (startTime: number, actionsForDispatch: string[]) => {
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    if (duration < 500) {
      setTimeout(() => {
        actionsForDispatch.forEach((action) => dispatch({ type: action, value: false }));
      }, 500 - duration);
    } else {
      actionsForDispatch.forEach((action) => dispatch({ type: action, value: false }));
    }
  };

  const getProcedures = (specialtyID: number) => {
    const startTime = new Date().getTime();
    dispatch({ type: actions.PROCEDURES_LOADING, value: true });
    axiosGetProceduresForSpecialtyAndProvider(Number(process.env.REACT_APP_PROVIDER_ID), specialtyID, Number(process.env.REACT_APP_SHEDULER_ID), sessionCodeRef?.current)
      .then((responseProcedures: AxiosResponse) => {
        dispatch({ type: actions.PROCEDURES, value: responseProcedures.data.data });
      })
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          handleAxiosCallError(showMessage, e);
        }
      })
      .finally(() => {
        checkIfTimeoutNeeded(startTime, [actions.PROCEDURES_LOADING]);
      });
  };

  const getLocationsDoctorsForSpecialtyAndProvider = (specialtyID: number, procedureID: number) => {
    const resLocations = axiosGetLocationsForProcedureAndProvider(Number(process.env.REACT_APP_PROVIDER_ID), procedureID, sessionCodeRef?.current);
    const resDoctors = axiosGetDoctorsForSpecialtyProviderAndProcedure(Number(process.env.REACT_APP_PROVIDER_ID), specialtyID, procedureID, sessionCodeRef?.current);
    const startTime = new Date().getTime();
    dispatch({ type: actions.LOCATIONS_LOADING, value: true });
    dispatch({ type: actions.DOCTORS_LOADING, value: true });
    axios
      .all([resLocations, resDoctors])
      .then(
        axios.spread((responseLocations, responseDoctors) => {
          dispatch({ type: actions.LOCATIONS, value: responseLocations.data.data });
          dispatch({ type: actions.DOCTORS, value: responseDoctors.data.data });
        })
      )
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          handleAxiosCallError(showMessage, e);
        }
      })
      .finally(() => {
        checkIfTimeoutNeeded(startTime, [actions.LOCATIONS_LOADING, actions.DOCTORS_LOADING]);
      });
  };

  const validatePatientStep = () => {
    if (!validatePatientInformation()) {
      return;
    }
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "34", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
    dispatch({ type: actions.INCREMENT_STEP });
  };

  const zeroToFirstStepAction = () => {
    dispatch({ type: actions.SHOW_DISCLAIMER, value: false });
    localStorage.setItem("acceptedDisclaimer", "true");
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "01", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
  };

  const firstToSecondStepAction = () => {
    searchAppointment();
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "12", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
  };

  const secondToFirstStepAction = () => {
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "21", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
    dispatch({ type: actions.DECREMENT_STEP });
  };

  const secondToThirdStepAction = () => {
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "23", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
    dispatch({ type: actions.INCREMENT_STEP });
  };

  const thirdToSecondStepAction = () => {
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "32", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
    dispatch({ type: actions.DECREMENT_STEP });
  };

  const thirdToFourthStepAction = () => {
    validatePatientStep();
  };

  const fourthToThirdStepAction = () => {
    axiosGetWizardStep(Number(process.env.REACT_APP_SHEDULER_ID), "43", sessionCodeRef?.current).catch((e) => {
      if (!e.response) {
        dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
      } else {
        handleAxiosCallError(showMessage, e);
      }
    });
    dispatch({ type: actions.DECREMENT_STEP });
  };

  return {
    state,
    dispatch,
    items,
    appointmentsSearch,
    setAppointmentsSearch,
    searchAppointment,
    clearFirstStep,
    invalidFields,
    setInvalidFields,
    isNextButtonDisabled,
    reserveAppointment,
    checkServiceAvailability,
    captchaRef,
    onVerify,
    getLocationsDoctorsForSpecialtyAndProvider,
    getProcedures,
    validatePatientStep,
    sessionCodeRef,
    firstToSecondStepAction,
    secondToFirstStepAction,
    secondToThirdStepAction,
    thirdToSecondStepAction,
    thirdToFourthStepAction,
    fourthToThirdStepAction,
    zeroToFirstStepAction,
  };
}
