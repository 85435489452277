import {Button} from "primereact/button";
import {Image} from "primereact/image";
import {useNavigate} from "react-router-dom";
import StaticHeader from "../static-header/StaticHeader";
import Images from "../../infrastructure/system/Images";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import Footer from "../footer/Footer";
import {useEffect} from "react";
import {useBeforeunload} from "react-beforeunload";

export default function TransactionCanceled() {
  const navigate = useNavigate();

  // brisemo iteme za slucaj da korisnik koristi back button umesto dugme na pocetak
  useEffect(() => {
    return () => {
      localStorage.removeItem("paymentInfo");
      localStorage.removeItem("reservationUUID");
    };
  }, []);

  useBeforeunload(() => {
    localStorage.removeItem("paymentInfo");
    localStorage.removeItem("reservationUUID");
  });

  return (
    <>
      <StaticHeader />
      <div className="flex flex-row align-items-center justify-content-center error-layout print">
        <div className="error-info-box text-center col-11 lg:col-10 my-1 md:my-0 xl:col-6 p-5">
          <div className="error-info-box--icon-wrapper text-center mb-5">
            <Image src={Images.ICON_ERROR} />
          </div>
          <div className="error-info-box--title text-center">{Labels.LABEL_TRANSACTION_CANCELED_TITLE}</div>
          <div className="flex flex-row justify-content-center mb-5">
            <div className="error-info-box--items">{Labels.LABEL_TRANSACTION_ERROR_MESSAGE}</div>
          </div>
          <div className="flex flex-row flex-wrap justify-content-center no-print">
            <Button
              label={Labels.LABEL_SUCCESS_TO_START}
              onClick={() => {
                localStorage.removeItem("paymentInfo");
                localStorage.removeItem("reservationUUID");
                navigate("/");
              }}
              className="border-radius-0"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
