const Labels = {
  // Labels
  LABEL_PRETRAGA: "Pretraga",
  LABEL_TERMINI: "Termini",
  LABEL_USLUGE: "Usluge",
  LABEL_USLUGA: "Usluga",
  LABEL_USLUGE_SVE: "Sve usluge",
  LABEL_USLUGE_CHOOSE: "Izaberite uslugu",
  LABEL_PACIJENT: "Pacijent",
  LABEL_ZAKAZIVANJE: "Zakazivanje",
  LABEL_NAZAD: "Nazad",
  LABEL_PRETRAZI: "Pretraži",
  LABEL_PATIENT_INFO: "Podaci o pacijentu",
  LABEL_PLACEHOLDER_FIRSTNAME: "Ime",
  LABEL_PLACEHOLDER_LASTNAME: "Prezime",
  LABEL_PLACEHOLDER_PHONE: "Kontakt telefon",
  LABEL_PLACEHOLDER_EMAIL: "Imejl",
  LABEL_PLACEHOLDER_DATE_OF_BIRTH: "Datum rođenja",
  LABEL_PLACEHOLDER_ADDRESS: "Adresa",
  LABEL_OCISTI_POLJA: "Očisti polja",
  LABEL_NASTAVI: "Nastavi",
  LABEL_DATUM_OD_PLACEHOLDER: "Datum od",
  LABEL_DATUM_DO_PLACEHOLDER: "Datum do",
  LABEL_VREME_OD_PLACEHOLDER: "Vreme od",
  LABEL_VREME_DO_PLACEHOLDER: "Vreme do",
  LABEL_SPECIJALIZACIJA: "Specijalizacija",
  LABEL_CHOOSE_SPECIJALIZACIJA: "Izaberite specijalizaciju",
  LABEL_SPECIJALIZACIJA_SVE: "Sve specijalizacije",
  LABEL_LOKACIJA: "Lokacija",
  LABEL_LOKACIJA_SVE: "Sve lokacije",
  LABEL_LEKAR: "Lekar",
  LABEL_LEKAR_SVI: "Svi lekari",
  LABEL_PONISTI_IZBOR: "Poništi izbor",
  LABEL_ZAKAZI: "Zakaži",
  LABEL_REQUIRED_FIELD: "Polje je obavezno",
  LABEL_INVALID_DATE_TO: "Datum do mora biti posle datuma od",
  LABEL_INVALID_TIME_TO: "Vreme do mora biti posle vremena od",
  LABEL_EMAIL_NOT_VALID: "Imejl je pogrešnog formata",
  LABEL_PHONE_NUMBER_NOT_VALID: "Broj telefona je pogrešnog formata",
  LABEL_RESERVATION_CREATE_SUCCESS_MESSAGE: "Uspešno ste rezervisali termin.",
  LABEL_SCHEDULE_CREATE_SUCCESS_MESSAGE: "Uspešno ste zakazali termin.",
  LABEL_NO_AVAILABLE_APPOINTMENTS: "Nema slobodnih termina za parametre pretrage, molim vas ponovite pretragu.",
  LABEL_503: "503",
  LABEL_SERVICE_UNAVAILABLE: "Servis nije dostupan.",
  LABEL_SERVICE_UNAVAILABLE_TEXT: "Molimo pokušajte kasnije ili pozovite",
  LABEL_SERVICE_UNAVAILABLE_TEXT_PHONE: "0800 1122333",
  LABEL_SERVICE_CONNECTION_RETRY: "Pokušavamo da uspostavimo konekciju",
  LABEL_DISCLAIMER_HEADER: "USLOVI INTERNET ZAKAZIVANЈA",
  LABEL_DISCLAIMER_ACCEPT: "Prihvatam",
  LABEL_DISCLAIMER_DECLINE: "Odbijam",
  LABEL_DISCLAIMER_DECLINE_NOTE: "Ukoliko potvrdite, bićete preusmereni na sajt Euromedika.",
  LABEL_CARD_HOLDER: "Vlasnik kartice",
  LABEL_CARD_NUMBER: "Broj kartice",
  LABEL_CVC: "CVC",
  LABEL_EXPIRATION_MONTH: "Mesec isteka",
  LABEL_EXPIRATION_YEAR: "Godina isteka",
  LABEL_PAYMENT: "Plaćanje",
  LABEL_RESERVE: "Rezerviši",
  BUTTON_YES: "Da",
  BUTTON_NO: "Ne",
  SPECIAL_CHAR_REQUIRED: " *",
  LABEL_SEARCH_DATE_FROM_DESCRIPTION: "Datum početka pretrage termina",
  LABEL_SEARCH_DATE_TO_DESCRIPTION: "Datum kraja pretrage termina",
  LABEL_SEARCH_TIME_FROM_DESCRIPTION: "Vreme početka traženog termina",
  LABEL_SEARCH_TIME_TO_DESCRIPTION: "Vreme kraja traženog termina",
  LABEL_SEARCH_SPECIALISATIONS_DESCRIPTION: "Spisak specijalizacija za traženi termin",
  LABEL_SEARCH_SERVICES_DESCRIPTION: "Spisak usluga za traženi termin",
  LABEL_SEARCH_LOCATIONS_DESCRIPTION: "Spisak lokacija za traženi termin",
  LABEL_SEARCH_DOCTORS_DESCRIPTION: "Spisak lekara za traženi termin",
  LABEL_PROCESSING_PAYMENT_POPUP_TITLE: "Obrada u toku …",
  LABEL_PROCESSING_PAYMENT_POPUP_TEXT_1: "Uobičajeno vreme čekanja je do 5 minuta.",
  LABEL_PROCESSING_PAYMENT_POPUP_TEXT_2: "Molimo Vas nemojte zatvarati browser za vreme obrade.",
  LABEL_PRIVACY_POLICY_CHECKBOX_1: "Upoznat/a sam i saglasan/na sa",
  LABEL_PRIVACY_POLICY_CHECKBOX_2: "Politikom privatnosti",
  LABEL_SUCCESS_TITLE: "Usluga uspešno naplaćena!",
  LABEL_SUCCESS_TRANSACTION_ID: "ID transakcije",
  LABEL_SUCCESS_TRANSACTION_AMOUNT: "Plaćeni iznos",
  LABEL_SUCCESS_PRINT: "Štampaj",
  LABEL_SUCCESS_TO_START: "Na početak",
  MESSAGES_NO_RESULT_FOUND: "Nema rezultata",
  LABEL_TRANSACTION_ERROR_TITLE: "Došlo je do greške!",
  LABEL_TRANSACTION_ERROR_MESSAGE: "Molimo pokušajte ponovo.",
  LABEL_TRANSACTION_CANCELED_TITLE: "Transakcija otkazana!",
  LABEL_LOCATIONS_TITLE: "EUROMEDIK LOKACIJE",
  LABEL_LOCATIONS_KARABURMA: "KARABURMA",
  LABEL_LOCATIONS_KARABURMA_ADDRESS: "Višnjička 72; T: 011 / 7357 500",
  LABEL_LOCATIONS_VIDIKOVAC: "VIDIKOVAC",
  LABEL_LOCATIONS_VIDIKOVAC_ADDRESS: "Kneza Višeslava 63 a; T: 011 / 7859 424",
  LABEL_LOCATIONS_STARI_GRAD: "STARI GRAD",
  LABEL_LOCATIONS_STARI_GRAD_ADDRESS: "Cara Uroša 37; T: 011 / 7357 304",
  LABEL_LOCATIONS_ZVEZDARA: "ZVEZDARA",
  LABEL_LOCATIONS_ZVEZDARA_ADDRESS: "Vatroslava Jagića 1; T: 011 / 2448 148",
  LABEL_LOCATIONS_BIGGEST_HOSPITAL: "NAJVEĆA PRIVATNA BOLNICA",
  LABEL_LOCATIONS_COMPLETE_SURGERY: "KOMPLETNA HIRURGIJA I BOLNIČKO LEČENjE",
  LABEL_LOCATIONS_BIGGEST_HOSPITAL_ADDRESS: "Bulevar umetnosti 29; T: 011 / 7359 153; 7359 154; 7359 155;",
  LABEL_LOCATIONS_VOZDOVAC: "VOŽDOVAC",
  LABEL_LOCATIONS_VOZDOVAC_ADDRESS: "Stevana Prvovenčanog 32; T: 011 / 655 78 75",
  LABEL_LOCATIONS_NOVI_BEOGRAD: "NOVI BEOGRAD",
  LABEL_LOCATIONS_NOVI_BEOGRAD_ADDRESS: "Pariske komune 22; T: 011 / 2609 657",
  LABEL_LOCATIONS_ZEMUN: "ZEMUN",
  LABEL_LOCATIONS_ZEMUN_ADDRESS: "Kosovska 12; T: 011 / 2616 444",
  LABEL_LOCATIONS_CUKARICA: "ČUKARICA",
  LABEL_LOCATIONS_CUKARICA_ADDRESS: "Trebevićka 7; T: 011 / 6830 582",
  LABEL_LOCATIONS_PALILULA: "PALILULA",
  LABEL_LOCATIONS_PALILULA_ADDRESS: "Cvijićeva 42; T: 011 / 3294 457",
  LABEL_LOCATIONS_NOVI_BEOGRAD_2: "NOVI BEOGRAD",
  LABEL_LOCATIONS_NOVI_BEOGRAD_2_ADDRESS: "Jurija Gagarina 36d; T: 011 / 2284 001",
  LABEL_LOCATIONS_SAVSKI_VENAC: "SAVSKI VENAC",
  LABEL_LOCATIONS_SAVSKI_VENAC_ADDRESS: "Višegradska 20; T: 011 / 3650 660",
  LABEL_LOCATIONS_SLAVIJA: "SLAVIJA",
  LABEL_LOCATIONS_SLAVIJA_ADDRESS: "Alekse Nenadovića 7 i 8; T: 011 / 2444 111",
  LABEL_LOCATIONS_NOVI_SAD: "NOVI SAD",
  LABEL_LOCATIONS_NOVI_SAD_ADDRESS: "Nikole Pašića 17; M: 062 / 29 83 82",
  LABEL_LOCATIONS_PANCEVO: "PANČEVO",
  LABEL_LOCATIONS_PANCEVO_ADDRESS: "Dositeja Obradovića 8i; T: 013 / 2155 191; M: 062 / 204 172",
  LABEL_SPECIALIZATIONS_1: "Svi specijalistički pregledi",
  LABEL_SPECIALIZATIONS_2: "Kompletna dijagnostika",
  LABEL_SPECIALIZATIONS_3: "(MR, CT, RTG, endoskopija...)",
  LABEL_SPECIALIZATIONS_4: "Laboratorija",
  LABEL_SPECIALIZATIONS_5: "Fizikalna terapija",
  LABEL_SPECIALIZATIONS_6: "Sve operacije",
  LABEL_SPECIALIZATIONS_7: "Stacionarno lečenje",
  LABEL_SPECIALIZATIONS_8: "Vantelesna oplodnja",
  LABEL_SPECIALIZATIONS_9: "Porodilište",
  LABEL_SPECIALIZATIONS_10: "Stomatologija",
  LABEL_SPECIALIZATIONS_11: "Kućne posete lekara",
  LABEL_VREME: "Vreme",
  LABEL_DOCTOR: "Doktor",
  LABEL_COPYRIGHTS: "© Sva prava zadržana, Heliant ",
  LABEL_CURRENCY_STATEMENT: "Sve cene na ovom sajtu iskazane su u dinarima. PDV je uračunat u cenu. Zadržavamo pravo promene cena usluga, bez prethodne najave na sajtu.",
  LABEL_PIB: ", PIB:",
  LABEL_NO_SLOT_DOCTORS_WARNING: "Za odabrani termin nema doktora, odaberite drugi termin ili pokušajte sa drugačijim parametrima pretrage",
  LABEL_DISCLAIMER_TEXT_1: "Ustanova Euromedik se obavezuje da će čuvati privatnost svih podataka korisnika sajta.",
  LABEL_DISCLAIMER_TEXT_2: "Zakazivanjem usluge putem sajta ostvarujete popust od 5%.",
  LABEL_DISCLAIMER_TEXT_3: "Ukoliko uslugu otkažete do 48 časova pre zakazanog termina, uplaćeni iznos možete iskoristiti za neku drugu uslugu ili sredstva refundirati u iznosu od 90%.",
  LABEL_DISCLAIMER_TEXT_4: "Ukoliko uslugu otkažete u okviru od 48 do 24 časa do zakazanog termina uplaćeni iznos možete iskoristiti za neku drugu uslugu ili sredstva refundirati u iznosu od 70%.",
  LABEL_DISCLAIMER_TEXT_5: "Ukoliko uslugu otkažete u okviru do 24 časa pre zakazanog termina, uplaćeni iznos možete iskoristiti za neku drugu uslugu ili sredstva refundirati u iznosu od 10%.",
  LABEL_DISCLAIMER_TEXT_6: "Dolazak pacijenta na zakazan termin molimo da bude 10 minuta pre početka pregleda.",
  LABEL_DISCLAIMER_TEXT_7: "Molimo da obavezno sa sobom ponesete medicinsku dokumentaciju od ranije (ukoliko je posedujete).",
  LABEL_DISCLAIMER_TEXT_8:
    "Nakon izvršenog plaćanja usluge na Vašu mejl adresu će stići potvrda o datumu i adresi pregleda, avansni račun i pisano uputstvo za pripremu za pregled koji obavljate (ukoliko priprema postoji).",
  LABEL_DISCLAIMER_TEXT_9: "Prilikom dolaska na pregled molimo da zaposlenoj radnici pokažete potvrdu o zakazanom pregledu i da sa sobom ponesete lični dokument sa slikom.",
  LABEL_DISCLAIMER_TEXT_10: "Za maloletna lica nije moguće zakazivanje termina ovim putem.",
  LABEL_DISCLAIMER_TEXT_11: "Usluge koje nije moguće zakazati putem sajta su hirurške intervencije i drugi komplikovani zahvati.",
  LABEL_DISCLAIMER_TEXT_12: "Za sve informacije, primedbe ili druga pitanja možete se obratiti na mejl adresu:",
  LABEL_DISCLAIMER_TEXT_13: "euromedik.internetzakazivanje@gmail.com",
  LABEL_DISCLAIMER_TEXT_14: "Otkazivanje usluga se vrši isključivo pisanim putem na mejl adresu ",
  LABEL_DISCLAIMER_TEXT_15: "i nakon toga popunjavanjem reklamacionog lista za potrebe refundacije.",
  LABEL_DISCLAIMER_TEXT_16: "Ukoliko se usluga ne otkaže, nije moguće izvršiti refundiranje sredstava.",
  LABEL_PAYMENT_CONDITIONS_1: "Saglasan/na sam sa",
  LABEL_PAYMENT_CONDITIONS_2: "Uslovima plaćanja *",
  LABEL_CONTACT: "Kontakt",
  LABEL_CONTACT_NAZIV: "Naziv:",
  LABEL_CONTACT_SEDISTE: "Sedište:",
  LABEL_CONTACT_MATICNI_BROJ: "Matični broj:",
  LABEL_CONTACT_PIB: "PIB:",
  LABEL_CONTACT_KONTAKT_TELEFON: "Kontakt telefon:",
  LABEL_CONTACT_PRAVNO_LICE_1_NAZIV: "Poliklinika Euromedik",
  LABEL_CONTACT_PRAVNO_LICE_1_SEDISTE: "Alekse Nenadovića 7, Beograd-Savski Venac",
  LABEL_CONTACT_PRAVNO_LICE_1_MATICNI_BROJ: "62444380",
  LABEL_CONTACT_PRAVNO_LICE_1_PIB: "107023382",
  LABEL_CONTACT_PRAVNO_LICE_1_KONTAKT_TELEFON: "011/244-8008",
  LABEL_CONTACT_PRAVNO_LICE_2_NAZIV: 'Opšta bolnica "Euromedik"',
  LABEL_CONTACT_PRAVNO_LICE_2_SEDISTE: "Višegradska 20, Beograd-Savski Venac",
  LABEL_CONTACT_PRAVNO_LICE_2_MATICNI_BROJ: "17837222",
  LABEL_CONTACT_PRAVNO_LICE_2_PIB: "107470692",
  LABEL_CONTACT_PRAVNO_LICE_2_KONTAKT_TELEFON: "011/3650660",
  LABEL_CONTACT_PRAVNO_LICE_3_NAZIV: 'Dom zdravlja "Euromedik"',
  LABEL_CONTACT_PRAVNO_LICE_3_SEDISTE: "Jurija Gagarina 36D, Beograd-Novi Beograd",
  LABEL_CONTACT_PRAVNO_LICE_3_MATICNI_BROJ: "17786423",
  LABEL_CONTACT_PRAVNO_LICE_3_PIB: "106406309",
  LABEL_CONTACT_PRAVNO_LICE_3_KONTAKT_TELEFON: "",
  LABEL_CONTACT_PRAVNO_LICE_4_NAZIV: "Opšta bolnica ''Euromedik 3''",
  LABEL_CONTACT_PRAVNO_LICE_4_SEDISTE: "Pariske komune 22, Beograd-Novi Beograd",
  LABEL_CONTACT_PRAVNO_LICE_4_MATICNI_BROJ: "17921355",
  LABEL_CONTACT_PRAVNO_LICE_4_PIB: "111343822",
  LABEL_CONTACT_PRAVNO_LICE_4_KONTAKT_TELEFON: "011/2609644",
  LABEL_CONTACT_PRAVNO_LICE_5_NAZIV: "Opšta bolnica ''Euromedik 2''",
  LABEL_CONTACT_PRAVNO_LICE_5_SEDISTE: "Bulevar umetnosti 29, Beograd-Novi Beograd",
  LABEL_CONTACT_PRAVNO_LICE_5_MATICNI_BROJ: "17900323",
  LABEL_CONTACT_PRAVNO_LICE_5_PIB: "109914031",
  LABEL_CONTACT_PRAVNO_LICE_5_KONTAKT_TELEFON: "",
  LABEL_CONTACT_PRAVNO_LICE_6_NAZIV: "Poliklinika Euromedic Diagnostic",
  LABEL_CONTACT_PRAVNO_LICE_6_SEDISTE: "Alekse Nenadovića 8, Beograd-Savski Venac",
  LABEL_CONTACT_PRAVNO_LICE_6_MATICNI_BROJ: "55362521",
  LABEL_CONTACT_PRAVNO_LICE_6_PIB: "101515753",
  LABEL_CONTACT_PRAVNO_LICE_6_KONTAKT_TELEFON: "",
  LABEL_CONTACT_INFORMATIONS_1: "Radno vreme:",
  LABEL_CONTACT_INFORMATIONS_2: "Ponedeljak – petak od 07:00 do 21:00",
  LABEL_CONTACT_INFORMATIONS_3: "Subota od 07:00 do 15:00",
  LABEL_CONTACT_INFORMATIONS_4: "Nedelja neradni dan",
  LABEL_CONTACT_INFORMATIONS_5: "Email-adresa:",
  LABEL_CONTACT_INFORMATIONS_6: "Kontakt telefon:",
  LABEL_CONTACT_INFORMATIONS_7: "+381628019228",
  LABEL_AMOUNT: "Iznos",
  LABEL_ORDER_NUMBER: "Broj porudžbine",
  LABEL_ORDER_DATE: "Datum transakcije",
  LABEL_PAYMENT_METHOD: "Metod plaćanja",
  LABEL_AUTHORIZATION_CODE: "Autorizacioni kod",
  LABEL_EXPIRATION_DATE: "Datum isteka kartice",
  LABEL_SECURITY_CODE: "Zaštitni kod",
  LABEL_CARD_EXPIRATION_PLACEHOLDER: "MM / YY",
  LABEL_CARD_HOLDER_EMPTY_ERRROR: "Vlasnik kartice je obavezan podataka",
  LABEL_CARD_NUMBER_EMPTY_ERROR: "Broj kartice je obavezan podataka",
  LABEL_CARD_NUMBER_INVALID_ERROR: "Broj kartice nije validan",
  LABEL_CARD_EXPIRATION_MONTH_EMPTY_ERROR: "Mesec isteka kartice je obavezan podataka",
  LABEL_CARD_EXPIRATION_MONTH_INVALID_ERROR: "Mesec isteka kartice nije validan",
  LABEL_CARD_EXPIRATION_YEAR_EMPTY_ERROR: "Godina isteka kartice je obavezan podataka",
  LABEL_CARD_EXPIRATION_YEAR_INVALID_ERROR: "Godina isteka kartice nije validna",
  LABEL_CARD_CVV_EMPTY_ERRROR: "Zaštitni kod je obavezan podataka",
  LABEL_CARD_CVV_INVALID_ERRROR: "Zaštitni kod nije validan",
  LABEL_APPOINTMENTS_HEADER_COUNT: "termina",
  LABEL_REQUIRED_FIELDS_NOTE: "* Obavezna polja su obeležena zvezdicom",
  PLACEHOLDER_DATUM_FORMAT: "DD.MM.GGGG.",
  LABEL_REKLAMACIJE: "Reklamacije",
};

export default Labels;
