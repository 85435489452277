import { Dialog } from "primereact/dialog";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";

interface DisclaimerPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<{ type: actions }> | any;
  zeroToFirstStepAction: () => void;
}

export default function Disclaimer(props: DisclaimerPropsType) {
  const { state, dispatch, zeroToFirstStepAction } = props;

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-content-center pt-3">
        <Button
          className="p-button-danger"
          label={Labels.LABEL_DISCLAIMER_DECLINE}
          onClick={() => {
            confirmPopup({
              message: Labels.LABEL_DISCLAIMER_DECLINE_NOTE,
              acceptLabel: Labels.BUTTON_YES,
              acceptClassName: "p-button-success",
              rejectClassName: "p-button-danger",
              rejectLabel: Labels.BUTTON_NO,
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                window.location.replace("https://euromedic.rs/");
              },
              reject: () => {},
            });
          }}
        />
        <Button
          className="p-button-success"
          onClick={() => zeroToFirstStepAction()}
          label={Labels.LABEL_DISCLAIMER_ACCEPT}
        />
      </div>
    );
  };

  return (
    <Dialog
      visible={state?.disclaimerVisible}
      onHide={() => dispatch({ type: actions.SHOW_DISCLAIMER, value: false })}
      header={Labels.LABEL_DISCLAIMER_HEADER}
      footer={state?.disclaimerFooterVisible && renderFooter}
      closable={!state?.disclaimerFooterVisible}
      className="w-10 sm:w-10 md:w-8 lg:w-6 disclaimerDialog"
      position="top">
      <ul className="pl-3">
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_1}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_2}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_3}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_4}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_5}</li>
        <li className="mb-2">
          <span>{Labels.LABEL_DISCLAIMER_TEXT_14}</span>
          <span>
            <a href="mailto:euromedik.internetzakazivanje@gmail.com" className="pt-2">
              {Labels.LABEL_DISCLAIMER_TEXT_13}
            </a>
          </span>
          {" " + Labels.LABEL_DISCLAIMER_TEXT_15}
        </li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_16}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_6}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_7}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_8}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_9}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_10}</li>
        <li className="mb-2">{Labels.LABEL_DISCLAIMER_TEXT_11}</li>
      </ul>
      {/*<p className="pl-1">*/}
      {/*  {Labels.LABEL_DISCLAIMER_TEXT_12} <br />*/}
      {/*  <span className="block mt-2">*/}
      {/*    <a href="mailto:euromedik.internetzakazivanje@gmail.com" className="pt-2">*/}
      {/*      {Labels.LABEL_DISCLAIMER_TEXT_13}*/}
      {/*    </a>*/}
      {/*  </span>*/}
      {/*</p>*/}
    </Dialog>
  );
}
