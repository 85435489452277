import { Dialog } from "primereact/dialog";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import { useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { checkEmpty } from "../../infrastructure/Utils";

interface NotInFunctionPopUpPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<{ type: actions }> | any;
}

export default function NotInFunctionPopUp(props: NotInFunctionPopUpPropsType) {
  const { state, dispatch } = props;
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const matchPassword = () => {
    if (password === "eurom3dikWebS@jt2024_b@nkaT3st") {
      dispatch({ type: actions.NOT_IN_FUNCTION, value: false });
    } else {
      setErrorMessage("Pogrešna šifra.");
    }
  };

  return (
    <Dialog onHide={() => {}} visible={state?.notInFunction} closable={false} className="w-10 md:w-6 lg:w-4">
      <div className="text-center notInFunctionPopup">
        <div className="font-bold">Ovaj sajt trenutno nije u funkciji.</div>
        <div className="font-bold mb-3">
          Za vise informacija kontaktirajte{" "}
          <a href="https://euromedic.rs/" target="_blank" rel="noreferrer">
            Euromedik
          </a>
        </div>
        <div className="mb-3 font-bold">*Sajt je još u testnoj fazi, u slučaju da imate pristupnu šifru unesite je i nastavite sa aktivnostima.</div>
        <div className="mb-3">
          <Password
            className="w-full"
            inputClassName="w-full"
            value={password ?? ""}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage("");
            }}
            placeholder="Unesite šifru"
            feedback={false}
            toggleMask
          />
          {!checkEmpty(errorMessage) && <small className="red">{errorMessage}</small>}
        </div>
        <Button onClick={matchPassword} label="Potvrdi" disabled={checkEmpty(password)} />
      </div>
    </Dialog>
  );
}
