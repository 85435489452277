import { useMemo } from "react";

const ObaveznoPolje = (props: { text: string; className?: string }) => {
  const { text, className } = props;

  const colorStyle = useMemo(() => {
    return { color: "red" };
  }, []);

  return (
    <small style={colorStyle} id="sifra-required" className={className}>
      {text}
    </small>
  );
};

export default ObaveznoPolje;
