import axios, { AxiosResponse } from "axios";
import Endpoint from "../infrastructure/system/Endpoint";
import SchedulerSearchCreateDto from "../model/SchedulerSearchCreateDto";
import AppointmentReservationCreateDto from "../model/AppointmentReservationCreateDto";
import { axiosConfig } from "../infrastructure/Utils";

export interface WizardControllerType {
  axiosGetSpecialisations: (schedulerId: number, providerId: number, sessionCode?: string) => Promise<AxiosResponse<any>>;
  axiosGetNonReservedAppointments: (searchAppointmentObject: SchedulerSearchCreateDto, schedulerId: number, sessionCode?: string) => Promise<AxiosResponse<any>>;
  axiosGetServiceAvailability: () => Promise<AxiosResponse<any>>;
  axiosGetProceduresForSpecialtyAndProvider: (providerID: number, specialtyID: number, schedulerId: number, sessionCode?: string) => Promise<AxiosResponse<any>>;
  axiosGetDoctorsForSpecialtyProviderAndProcedure: (providerID: number, specialtyID: number, procedureID: number, sessionCode?: string) => Promise<AxiosResponse<any>>;
  axiosGetLocationsForProcedureAndProvider: (providerID: number, procedureID: number, sessionCode?: string) => Promise<AxiosResponse<any>>;
  axiosAllSecureReserveAppointment: (appointmentData: AppointmentReservationCreateDto, sessionCode?: string) => Promise<AxiosResponse<any>>;
  axiosGetWarning: (sessionCode?: string) => Promise<AxiosResponse>;
  axiosGetWizardStep: (schedulerId: number, step: string, sessionCode?: string) => Promise<AxiosResponse>;
}

export default function WizardController(): WizardControllerType {
  const axiosGetSpecialisations = (schedulerId: number, providerId: number, sessionCode?: string) => {
    return axios.get(`${Endpoint.SPECIALISATIONS_LIST}/${schedulerId}/provider/${providerId}`, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosGetNonReservedAppointments = (searchAppointmentObject: SchedulerSearchCreateDto, schedulerId: number, sessionCode?: string) => {
    return axios.post(`${Endpoint.APPOINTMENT_SEARCH}/website/${schedulerId}`, searchAppointmentObject, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosGetServiceAvailability = () => {
    return axios.get(Endpoint.PUBLIC);
  };

  const axiosGetProceduresForSpecialtyAndProvider = (providerID: number, specialtyID: number, schedulerId: number, sessionCode?: string) => {
    return axios.get(`${Endpoint.PROCEDURE_LIST}/provider/${providerID}/specialty/${specialtyID}/website/${schedulerId}`, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosGetDoctorsForSpecialtyProviderAndProcedure = (providerID: number, specialtyID: number, procedureID: number, sessionCode?: string) => {
    return axios.get(`${Endpoint.DOCTORS_LIST_SPECIALTY}/provider/${providerID}/specialty/${specialtyID}/procedure/${procedureID}/website`, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosGetLocationsForProcedureAndProvider = (providerID: number, procedureID: number, sessionCode?: string) => {
    return axios.get(`${Endpoint.LOCATIONS_LIST}/provider/${providerID}/procedure/${procedureID}/website`, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosAllSecureReserveAppointment = (appointmentData: AppointmentReservationCreateDto, sessionCode?: string) => {
    return axios.post(`${Endpoint.ALL_SECURE_RESERVE}/${process.env.REACT_APP_SHEDULER_ID}`, appointmentData, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosGetWarning = (sessionCode?: string) => {
    return axios.get(Endpoint.WARNING, axiosConfig(sessionCode ? { sessionCode: sessionCode } : {}));
  };

  const axiosGetWizardStep = (schedulerId: number, step: string, sessionCode?: string) => {
    return axios.get(`${Endpoint.SCHEDULER_WEBSITE}/${schedulerId}/wizard`, axiosConfig(sessionCode ? { step: step, sessionCode: sessionCode } : {}));
  };

  return {
    axiosGetSpecialisations,
    axiosGetNonReservedAppointments,
    axiosGetServiceAvailability,
    axiosGetProceduresForSpecialtyAndProvider,
    axiosGetDoctorsForSpecialtyProviderAndProcedure,
    axiosGetLocationsForProcedureAndProvider,
    axiosAllSecureReserveAppointment,
    axiosGetWarning,
    axiosGetWizardStep,
  };
}
