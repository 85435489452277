import AppointmentReservationCreateDto from "../../../../model/AppointmentReservationCreateDto";
import ProcedureWithCurrentPriceReadDto from "../../../../model/ProcedureWithCurrentPriceReadDto";
import SpecialtyReadDto from "../../../../model/SpecialtyReadDto";
import LocationReadDto from "../../../../model/LocationReadDto";
import ProviderAppointmentReadDto from "../../../../model/ProviderAppointmentReadDto";
import ProcedureReadDto from "../../../../model/ProcedureReadDto";
import DoctorWithLocationReadDto from "../../../../model/DoctorWithLocationReadDto";

export interface InitialStateType {
  step: number;
  providerId: number | undefined;
  specialisations: Array<SpecialtyReadDto>;
  procedures: Array<ProcedureReadDto>;
  proceduresLoading: boolean;
  locations: Array<LocationReadDto>;
  locationsForSelect: Array<LocationReadDto>;
  locationsLoading: boolean;
  doctors: Array<DoctorWithLocationReadDto>;
  doctorsForSelect: Array<DoctorWithLocationReadDto>;
  doctorsLoading: boolean;
  filterDataLoading: boolean;
  specialtiesList: Array<SpecialtyReadDto>;
  appointment: AppointmentReservationCreateDto | any;
  appointments: Array<ProviderAppointmentReadDto>;
  appointmentsLoading: boolean;
  servicesLoading: boolean;
  services: Array<ProcedureWithCurrentPriceReadDto> | undefined;
  serviceAvailability: boolean;
  disclaimerVisible: boolean;
  disclaimerFooterVisible: boolean;
  captchaSuccess: boolean;
  refId: string | undefined;
  paymentProcessing: boolean;
  privacyPolicy: boolean;
  publicIntegrationKey: string | undefined;
  conditionsAgreement: boolean | undefined;
  notInFunction: boolean;
  warningText: string | undefined;
}

const InitialState: InitialStateType = {
  step: 0,
  providerId: undefined,
  appointment: undefined,
  specialisations: [],
  procedures: [],
  proceduresLoading: false,
  locations: [],
  locationsForSelect: [],
  locationsLoading: false,
  doctors: [],
  doctorsForSelect: [],
  doctorsLoading: false,
  filterDataLoading: false,
  specialtiesList: [],
  appointments: [],
  appointmentsLoading: false,
  servicesLoading: false,
  services: undefined,
  serviceAvailability: true,
  disclaimerVisible: false,
  disclaimerFooterVisible: false,
  captchaSuccess: false,
  refId: undefined,
  paymentProcessing: false,
  privacyPolicy: false,
  publicIntegrationKey: undefined,
  conditionsAgreement: false,
  notInFunction: false,
  warningText: undefined,
};

export default InitialState;
