import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import { Image } from "primereact/image";
import Images from "../../infrastructure/system/Images";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";

interface StaticHeaderPropsType {
  dispatch?: React.Dispatch<ReducerDispatchWithAppointmentDto>;
}

export default function StaticHeader(props: StaticHeaderPropsType) {
  const { dispatch } = props;

  const showAction = () => {
    if (dispatch) {
      dispatch({ type: actions.SHOW_DISCLAIMER_FOOTER, value: false });
      dispatch({ type: actions.SHOW_DISCLAIMER, value: true });
    }
  };

  return (
    <div className="static-header no-print">
      <div className="flex flex-row flex-wrap align-items-center">
        <div className="col-12 sm:col-2 text-center sm:text-left">
          <Image src={Images.EUROMEDIK_LOGO} width={"150px"} className="cursor-pointer" onClick={() => window.location.replace("https://euromedic.rs/")} />
        </div>
        <div className="col-12 sm:col-8 text-center">
          {window.location.pathname === "/contact" ? Labels.LABEL_REKLAMACIJE : Labels.LABEL_ZAKAZIVANJE} {dispatch && <i className="pi pi-info-circle cursor-pointer" onClick={() => showAction()} />}
        </div>
        <div className="col-12 sm:col-2 text-center sm:text-right right-logo">
          <Image src={Images.EUROMEDIK_LOGO} width={"150px"} className="cursor-pointer" onClick={() => window.location.replace("https://euromedic.rs/")} />
        </div>
      </div>
    </div>
  );
}
