import { Accordion, AccordionTab } from "primereact/accordion";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";

interface LocationsPropsType {
  additionalClass?: string;
}

export default function Locations(props: LocationsPropsType) {
  const { additionalClass } = props;

  return (
    <div className={`col-12 xl:col-3 locations-layout ${additionalClass ?? ""}`}>
      <div className="green-box text-white px-2 py-3">
        <h1 className="font-bold uppercase">{Labels.LABEL_LOCATIONS_TITLE}:</h1>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_KARABURMA}</span> {Labels.LABEL_LOCATIONS_KARABURMA_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_VIDIKOVAC}</span> {Labels.LABEL_LOCATIONS_VIDIKOVAC_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_STARI_GRAD}</span> {Labels.LABEL_LOCATIONS_STARI_GRAD_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_ZVEZDARA}</span> {Labels.LABEL_LOCATIONS_ZVEZDARA_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_BIGGEST_HOSPITAL}</span>
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_COMPLETE_SURGERY}</span>
        </div>
        <div className="mb-1">{Labels.LABEL_LOCATIONS_BIGGEST_HOSPITAL_ADDRESS}</div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_VOZDOVAC}</span> {Labels.LABEL_LOCATIONS_VOZDOVAC_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_NOVI_BEOGRAD}</span> {Labels.LABEL_LOCATIONS_NOVI_BEOGRAD_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_ZEMUN}</span> {Labels.LABEL_LOCATIONS_ZEMUN_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_CUKARICA}</span> {Labels.LABEL_LOCATIONS_CUKARICA_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_PALILULA}</span> {Labels.LABEL_LOCATIONS_PALILULA_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_NOVI_BEOGRAD_2}</span> {Labels.LABEL_LOCATIONS_NOVI_BEOGRAD_2_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_SAVSKI_VENAC}</span> {Labels.LABEL_LOCATIONS_SAVSKI_VENAC_ADDRESS}
        </div>
        <div className="mb-1">
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_SLAVIJA}</span> {Labels.LABEL_LOCATIONS_SLAVIJA_ADDRESS}
        </div>
        <div>
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_NOVI_SAD}</span> {Labels.LABEL_LOCATIONS_NOVI_SAD_ADDRESS}
        </div>
        <div>
          <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_PANCEVO}</span> {Labels.LABEL_LOCATIONS_PANCEVO_ADDRESS}
        </div>
      </div>
      <Accordion collapseIcon="pi pi-chevron-up" expandIcon="pi pi-chevron-down" className="m-auto">
        <AccordionTab headerTemplate={<h1>{Labels.LABEL_LOCATIONS_TITLE}</h1>} headerClassName="uppercase font-bold">
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_KARABURMA}</span> {Labels.LABEL_LOCATIONS_KARABURMA_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_VIDIKOVAC}</span> {Labels.LABEL_LOCATIONS_VIDIKOVAC_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_STARI_GRAD}</span> {Labels.LABEL_LOCATIONS_STARI_GRAD_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_ZVEZDARA}</span> {Labels.LABEL_LOCATIONS_ZVEZDARA_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_BIGGEST_HOSPITAL}</span>
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_COMPLETE_SURGERY}</span>
          </div>
          <div className="mb-1">{Labels.LABEL_LOCATIONS_BIGGEST_HOSPITAL_ADDRESS}</div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_VOZDOVAC}</span> {Labels.LABEL_LOCATIONS_VOZDOVAC_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_NOVI_BEOGRAD}</span> {Labels.LABEL_LOCATIONS_NOVI_BEOGRAD_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_ZEMUN}</span> {Labels.LABEL_LOCATIONS_ZEMUN_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_CUKARICA}</span> {Labels.LABEL_LOCATIONS_CUKARICA_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_PALILULA}</span> {Labels.LABEL_LOCATIONS_PALILULA_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_NOVI_BEOGRAD_2}</span> {Labels.LABEL_LOCATIONS_NOVI_BEOGRAD_2_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_SAVSKI_VENAC}</span> {Labels.LABEL_LOCATIONS_SAVSKI_VENAC_ADDRESS}
          </div>
          <div className="mb-1">
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_SLAVIJA}</span> {Labels.LABEL_LOCATIONS_SLAVIJA_ADDRESS}
          </div>
          <div>
            <span className="font-bold uppercase">{Labels.LABEL_LOCATIONS_NOVI_SAD}</span> {Labels.LABEL_LOCATIONS_NOVI_SAD_ADDRESS}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
