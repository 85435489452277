import { Toast } from "primereact/toast";
import { useContext } from "react";
import { AppContext } from "../Store";
import Content from "./Content";

export default function Scheduler() {
  const { message } = useContext(AppContext);

  return (
    <>
      <Toast ref={message} />
      <Content />
    </>
  );
}
