import { Skeleton } from "primereact/skeleton";

interface SkeletonInputItemProps {
  width?: string;
  height?: string;
}

export default function SkeletonInputItem(props: SkeletonInputItemProps) {
  const { width, height } = props;

  return <Skeleton className={"p-2 " + width} height={height ?? "2rem"}></Skeleton>;
}
