import moment from "moment/moment";
import { DATE_FORMAT, numberFormat, TIME_FORMAT } from "../../infrastructure/Utils";
import FrontendInformationDto from "../../model/FrontendInformationDto";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";

interface AppointmentBoxProps {
  boxInformation: FrontendInformationDto;
  additionalClass?: string;
  showPriceBlock: boolean;
  showAppointmentBlock: boolean;
}

export default function AppointmentBox(props: AppointmentBoxProps) {
  const { boxInformation, additionalClass, showPriceBlock, showAppointmentBlock } = props;

  return (
    <div className={`appointment-info mb-5 ${additionalClass ?? ""}`}>
      {showAppointmentBlock && (
        <div className="flex flex-row flex-wrap justify-content-start">
          <div className="appointment-info__item pr-3">
            <div className="appointment-info__date font-bold mb-3">{moment(boxInformation?.appointmentStartTime).format(DATE_FORMAT)}</div>
            <div className="appointment-info__time font-bold">
              {moment(boxInformation?.appointmentStartTime).format(TIME_FORMAT)} - {moment(boxInformation?.appointmentEndTime).format(TIME_FORMAT)}
            </div>
          </div>
          <div className="appointment-info__item pl-3">
            <div className="appointment-info__location mb-3">
              {boxInformation?.providerLegalEntityName} {Labels.LABEL_PIB} {boxInformation?.pib}
            </div>
            <div className="appointment-info__location mb-3">
              {boxInformation?.locationName} - {boxInformation?.locationAddress}
            </div>
            <div className="appointment-info__specialisation mb-3">{boxInformation?.specialtyName}</div>
            <div className="appointment-info__doctor">{boxInformation?.doctorName}</div>
          </div>
        </div>
      )}
      {showPriceBlock && (
        <div className={`flex flex-row flex-wrap justify-content-between appointment-info__service-item ${!showAppointmentBlock ? "border-0 mt-0 pt-0" : "mt-3 pt-3"}`}>
          <div className="appointment-info__service">{boxInformation?.procedureName}</div>
          <div className="appointment-info__service-price font-bold green">{numberFormat(parseInt(boxInformation?.procedurePrice), 2, 2) + " " + boxInformation?.procedureCurrency}</div>
        </div>
      )}
    </div>
  );
}
