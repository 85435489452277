import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";

export default function ErrorElement() {
  return (
    <div className="error-wrapper flex flex-column justify-content-center align-items-center">
      <div className="error-wrapper__title">{Labels.LABEL_503}</div>
      <div className="error-wrapper__subtitle">{Labels.LABEL_SERVICE_UNAVAILABLE}</div>
      <div className="error-wrapper__text">
        {Labels.LABEL_SERVICE_UNAVAILABLE_TEXT}
        <a href={`tel:${Labels.LABEL_SERVICE_UNAVAILABLE_TEXT_PHONE}`} className="error-wrapper__link ml-2">
          {Labels.LABEL_SERVICE_UNAVAILABLE_TEXT_PHONE}
        </a>
        <div className="text-center mt-3">
          {Labels.LABEL_SERVICE_CONNECTION_RETRY} <i className="pi pi-spin pi-spinner"></i>
        </div>
      </div>
    </div>
  );
}
