import actions from "./actions";
import { InitialStateType } from "./initialState";

export default function reducer(state: InitialStateType, action: any) {
  switch (action.type) {
    case actions.INCREMENT_STEP: {
      return {
        ...state,
        step: state.step + 1,
      };
    }
    case actions.DECREMENT_STEP: {
      return {
        ...state,
        step: state.step - 1,
      };
    }
    case actions.SET_STEP: {
      return {
        ...state,
        step: action.value,
      };
    }
    case actions.SPECIALISATIONS: {
      return {
        ...state,
        specialisations: action.value,
      };
    }
    case actions.PROCEDURES: {
      return {
        ...state,
        procedures: action.value,
      };
    }
    case actions.PROCEDURES_LOADING: {
      return {
        ...state,
        proceduresLoading: action.value,
      };
    }
    case actions.LOCATIONS: {
      return {
        ...state,
        locations: action.value,
        locationsForSelect: action.value,
      };
    }
    case actions.LOCATIONS_FOR_SELECT: {
      return {
        ...state,
        locationsForSelect: action.value,
      };
    }
    case actions.LOCATIONS_LOADING: {
      return {
        ...state,
        locationsLoading: action.value,
      };
    }
    case actions.DOCTORS: {
      return {
        ...state,
        doctors: action.value,
        doctorsForSelect: action.value,
      };
    }
    case actions.DOCTORS_FOR_SELECT: {
      return {
        ...state,
        doctorsForSelect: action.value,
      };
    }
    case actions.DOCTORS_LOADING: {
      return {
        ...state,
        doctorsLoading: action.value,
      };
    }
    case actions.FILTER_DATA_LOADING: {
      return {
        ...state,
        filterDataLoading: action.value,
      };
    }
    case actions.SPECIALITIES_LIST: {
      return {
        ...state,
        specialtiesList: action.value,
      };
    }
    case actions.APPOINTMENT_CHANGE: {
      return {
        ...state,
        appointment: { ...state.appointment, [action.appointment.fieldName]: action.appointment.value },
      };
    }
    case actions.APPOINTMENTS: {
      return {
        ...state,
        appointments: action.value,
      };
    }
    case actions.APPOINTMENTS_LOADING: {
      return {
        ...state,
        appointmentsLoading: action.value,
      };
    }
    case actions.CLEAR_APPOINTMENT: {
      return {
        ...state,
        appointment: undefined,
      };
    }
    case actions.SERVICES_LOADING: {
      return {
        ...state,
        servicesLoading: action.value,
      };
    }
    case actions.SERVICES: {
      return {
        ...state,
        services: action.value,
      };
    }
    case actions.SERVICE_AVAILABILITY: {
      return {
        ...state,
        serviceAvailability: action.value,
      };
    }
    case actions.SHOW_DISCLAIMER: {
      return {
        ...state,
        disclaimerVisible: action.value,
      };
    }
    case actions.SHOW_DISCLAIMER_FOOTER: {
      return {
        ...state,
        disclaimerFooterVisible: action.value,
      };
    }
    case actions.CAPTCHA_SUCCESS: {
      return {
        ...state,
        captchaSuccess: action.value,
      };
    }
    case actions.SET_REF_ID: {
      return {
        ...state,
        refId: action.value,
      };
    }
    case actions.RESET: {
      return {
        ...state,
        step: 0,
        appointment: undefined,
        specialisations: [],
        locations: [],
        doctors: [],
        filterDataLoading: false,
        specialtiesList: [],
        appointments: [],
        appointmentsLoading: false,
        servicesLoading: false,
        services: undefined,
        serviceAvailability: true,
        disclaimerVisible: false,
        captchaSuccess: false,
        refId: undefined,
      };
    }
    case actions.PAYMENT_PROCESSING: {
      return {
        ...state,
        paymentProcessing: action.value,
      };
    }
    case actions.PRIVACY_POLICY: {
      return {
        ...state,
        privacyPolicy: action.value,
      };
    }
    case actions.PUBLIC_INTEGRATION_KEY: {
      return {
        ...state,
        publicIntegrationKey: action.value,
      };
    }
    case actions.CONDITIONS_AGREEMENT: {
      return {
        ...state,
        conditionsAgreement: action.value,
      };
    }
    case actions.SET_PROVIDER_ID: {
      return {
        ...state,
        providerId: action.value,
      };
    }
    case actions.NOT_IN_FUNCTION: {
      return {
        ...state,
        notInFunction: action.value,
      };
    }
    case actions.SET_WARNING_TEXT: {
      return {
        ...state,
        warningText: action.value,
      };
    }
    default:
      return state;
  }
}
