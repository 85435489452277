import {Button} from "primereact/button";
import {Image} from "primereact/image";
import {useNavigate} from "react-router-dom";
import StaticHeader from "../static-header/StaticHeader";
import Images from "../../infrastructure/system/Images";
import {DATE_FORMAT, numberFormat, TIME_FORMAT} from "../../infrastructure/Utils";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import moment from "moment";
import Footer from "../footer/Footer";
import {useEffect} from "react";
import {useBeforeunload} from 'react-beforeunload';

export default function SuccessPage() {
  const navigate = useNavigate();
  const paymentInfo = JSON.parse(localStorage.getItem("paymentInfo")!);

  // brisemo iteme za slucaj da korisnik koristi back button umesto dugme na pocetak
  useEffect(() => {
    return () => {
      localStorage.removeItem("paymentInfo");
      localStorage.removeItem("reservationUUID");
    };
  }, []);

  useBeforeunload(() => {
    localStorage.removeItem("paymentInfo");
    localStorage.removeItem("reservationUUID");
  });

  return (
    <>
      <StaticHeader />
      <div className="flex flex-row align-items-center justify-content-center success-layout print">
        <div className="success-info-box text-center col-11 lg:col-10 my-4 md:my-0 xl:col-6 p-5">
          <div className="success-info-box--icon-wrapper text-center mb-5 no-print">
            <Image src={Images.CHECK_SUCCESS_ICON} />
          </div>
          <div className="success-info-box--title text-center">{Labels.LABEL_SUCCESS_TITLE}</div>
          <div className="flex flex-row justify-content-center">
            <div className="success-info-box--items col-12">
              {paymentInfo?.merchantId && (
                <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                  <span className="block md:inline text-left font-semibold">{Labels.LABEL_ORDER_NUMBER}:</span>
                  <span className="block md:inline text-left font-semibold">{paymentInfo?.merchantId}</span>
                </div>
              )}
              {paymentInfo?.paymentMethodUsed && (
                <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                  <span className="block md:inline text-left font-semibold">{Labels.LABEL_PAYMENT_METHOD}:</span>
                  <span className="block md:inline text-left font-semibold">{paymentInfo?.paymentMethodUsed}</span>
                </div>
              )}
              {paymentInfo?.authorizationCode && (
                <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                  <span className="block md:inline text-left font-semibold">{Labels.LABEL_AUTHORIZATION_CODE}:</span>
                  <span className="block md:inline text-left font-semibold">{paymentInfo?.authorizationCode}</span>
                </div>
              )}
              {paymentInfo?.dateTimeOfTransaction && (
                <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                  <span className="block md:inline text-left font-semibold">{Labels.LABEL_ORDER_DATE}:</span>
                  <span className="block md:inline text-left font-semibold">
                    {moment(paymentInfo?.dateTimeOfTransaction).format(DATE_FORMAT)} {moment(paymentInfo?.dateTimeOfTransaction).format(TIME_FORMAT)}
                  </span>
                </div>
              )}
              <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                <span className="block md:inline text-left font-semibold">{Labels.LABEL_USLUGA}:</span>
                <span className="block md:inline text-left font-semibold">{paymentInfo?.procedureName}</span>
              </div>
              <div className="block md:flex flex-row flex-wrap justify-content-between mb-4">
                <span className="block md:inline text-left font-semibold">{Labels.LABEL_SUCCESS_TRANSACTION_AMOUNT}:</span>
                <span className="block md:inline text-left font-semibold">{numberFormat(paymentInfo?.procedurePrice, 2, 2) + " " + paymentInfo?.procedureCurrency}</span>
              </div>
              <div className="block md:flex flex-row flex-wrap justify-content-between mb-4">
                <span className="block md:inline text-left font-semibold">{Labels.LABEL_VREME}:</span>
                <span className="block md:inline text-left font-semibold">
                  {moment(paymentInfo?.appointmentStartTime).format(DATE_FORMAT)} {moment(paymentInfo?.appointmentStartTime).format(TIME_FORMAT)} -{" "}
                  {moment(paymentInfo?.appointmentEndTime).format(TIME_FORMAT)}{" "}
                </span>
              </div>
              <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                <span className="block md:inline text-left font-semibold">{Labels.LABEL_LOKACIJA}:</span>
                <span className="block md:inline text-left font-semibold">
                  {paymentInfo?.locationName} - {paymentInfo?.locationAddress}
                </span>
              </div>
              <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                <span className="block md:inline text-left font-semibold">{Labels.LABEL_DOCTOR}:</span>
                <span className="block md:inline text-left font-semibold">{paymentInfo?.doctorName}</span>
              </div>
            </div>
          </div>
          <div className="flex-row flex-wrap justify-content-center no-print">
            <Button label={Labels.LABEL_SUCCESS_PRINT} className="p-button-outlined mb-0 sm:mb-0 sm:mr-5" onClick={() => window.print()} />
            <Button
              label={Labels.LABEL_SUCCESS_TO_START}
              onClick={() => {
                localStorage.removeItem("paymentInfo");
                localStorage.removeItem("reservationUUID");
                navigate("/");
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
