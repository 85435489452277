import StaticHeader from "../static-header/StaticHeader";
import Footer from "../footer/Footer";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";

export default function Contact() {
  return (
    <>
      <StaticHeader />
      <div className="flex flex-row justify-content-center">
        <div className="col-12 xl:col-6 contact-layout">
          <h3>{Labels.LABEL_CONTACT}</h3>
          <div className="flex flex-row flex-wrap justify-content-start">
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_NAZIV}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_1_NAZIV}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_SEDISTE}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_1_SEDISTE}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_MATICNI_BROJ}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_1_MATICNI_BROJ}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_PIB}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_1_PIB}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_NAZIV}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_2_NAZIV}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_SEDISTE}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_2_SEDISTE}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_MATICNI_BROJ}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_2_MATICNI_BROJ}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_PIB}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_2_PIB}
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-content-start">
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_NAZIV}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_3_NAZIV}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_SEDISTE}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_3_SEDISTE}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_MATICNI_BROJ}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_3_MATICNI_BROJ}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_PIB}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_3_PIB}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_NAZIV}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_4_NAZIV}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_SEDISTE}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_4_SEDISTE}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_MATICNI_BROJ}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_4_MATICNI_BROJ}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_PIB}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_4_PIB}
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-content-start">
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_NAZIV}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_5_NAZIV}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_SEDISTE}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_5_SEDISTE}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_MATICNI_BROJ}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_5_MATICNI_BROJ}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_PIB}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_5_PIB}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_NAZIV}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_6_NAZIV}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_SEDISTE}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_6_SEDISTE}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_MATICNI_BROJ}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_6_MATICNI_BROJ}
              </div>
              <div className="mb-2">
                <span className="font-bold">{Labels.LABEL_CONTACT_PIB}</span> {Labels.LABEL_CONTACT_PRAVNO_LICE_6_PIB}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 pl-0">
            <div className="mb-2">
              <i className="pi pi-clock mr-2" />
              <span className="font-bold">{Labels.LABEL_CONTACT_INFORMATIONS_1}</span>
            </div>
            <div className="mb-1 pl-3">{Labels.LABEL_CONTACT_INFORMATIONS_2}</div>
            <div className="mb-1 pl-3">{Labels.LABEL_CONTACT_INFORMATIONS_3}</div>
            <div className="mb-3 pl-3">{Labels.LABEL_CONTACT_INFORMATIONS_4}</div>
            <div className="mb-2">
              <i className="pi pi-envelope mr-2" />
              <span className="font-bold">{Labels.LABEL_CONTACT_INFORMATIONS_5}</span>
            </div>
            <a href="mailto:euromedik.internetzakazivanje@gmail.com" className="pl-3">
              {Labels.LABEL_DISCLAIMER_TEXT_13}
            </a>
            <div className="mb-2 mt-3">
              <i className="pi pi-phone mr-2" />
              <span className="font-bold">{Labels.LABEL_CONTACT_INFORMATIONS_6}</span>
            </div>
            <a href="tel:+381628019228" className="pl-3 no-underline phone-link">
              {Labels.LABEL_CONTACT_INFORMATIONS_7}
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
