import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import PaymentLogical, { PaymentLogicalType } from "./PaymentLogical";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import Locations from "../static-content/Locations";
import Specialization from "../static-content/Specialization";
import { Checkbox } from "primereact/checkbox";
import AppointmentBox from "../appointment-box/AppointmentBox";
// @ts-ignore
import UsloviPlacanja from "../../infrastructure/documents/uslovi.pdf";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import { RefObject } from "react";

interface PaymentPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
  sessionCodeRef: RefObject<string>;
}

export default function Payment(props: PaymentPropsType) {
  const { state, dispatch, sessionCodeRef } = props;
  const { cardHolderRef, mmYYRef, ValidateExpiry, expiryField, removeWarning, onPaymentSubmit }: PaymentLogicalType = PaymentLogical(dispatch, state, sessionCodeRef);

  return (
    <>
      <div className="flex flex-column xl:flex-row justify-content-between col-12 pt-4 xl:pt-8">
        <Locations />
        <div className="payment-layout patient-info-layout col-12 sm:col-10 md:col-10 lg:col-6 xl:col-6 payment-form-wrapper" id="payment-form">
          <div className="flex justify-content-center">
            <AppointmentBox boxInformation={state.appointment.frontendInformation} showPriceBlock={true} showAppointmentBlock={true} additionalClass={"col-12"} />
          </div>
          <div className="mb-4 ml-5 text-left patient-info-title">{Labels.LABEL_PAYMENT}</div>
          <input type="hidden" name="transaction_token" id="transaction_token" />
          <div className="mb-2">{Labels.LABEL_CARD_NUMBER + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="mb-5 relative">
            <i className="pi pi-credit-card input-icons" />
            <div className="card-type" id="card_type"></div>
            <div id="number_div" className="card-number-wrapper"></div>
            <small className="p-error" id="card_number"></small>
          </div>
          <div className="mb-2">{Labels.LABEL_CARD_HOLDER + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="mb-5 relative">
            <span className="p-input-icon-left block">
              <i className="pi pi-user" />
              <InputText id="card_holder" className="w-full" ref={cardHolderRef} /*placeholder={Labels.LABEL_CARD_HOLDER}*/ onChange={removeWarning} />
              <div className="error" id="error-card-holder"></div>
            </span>
            <small className="p-error" id="card_holder_error"></small>
          </div>
          <div className="flex flex-row flex-wrap justify-content-between p-0">
            <div className="col-12 sm:col-6 py-0 pl-0 pr-0 sm:pr-3">
              <div className="mb-2">{Labels.LABEL_EXPIRATION_DATE + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <span className="p-input-icon-left block mb-5 relative">
                <i className="pi pi-calendar" />
                <InputText
                  ref={mmYYRef}
                  className="w-full"
                  name="card.expiry"
                  id="card.expiry"
                  type="tel"
                  maxLength={5}
                  placeholder={Labels.LABEL_CARD_EXPIRATION_PLACEHOLDER}
                  onKeyUp={(e) => expiryField(e)}
                  onBlur={ValidateExpiry}
                  onChange={removeWarning}
                />
                <small className="p-error" id="card_month_year"></small>
                <div className="error" id="error-expiration"></div>
              </span>
            </div>
            <div className="col-12 sm:col-6 py-0 pr-0 pl-0 sm:pl-3">
              <div className="mb-2">{Labels.LABEL_SECURITY_CODE + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="mb-5 relative">
                <i className="pi pi-lock input-icons" />
                <div id="cvv_div" className="cvc-wrapper"></div>
                <small className="p-error" id="card_cvc"></small>
                <div className="error" id="error-cvc"></div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Checkbox
              inputId="patient-accept-privacy-policy"
              checked={state?.conditionsAgreement ?? false}
              onChange={(e) => {
                dispatch({ type: actions.CONDITIONS_AGREEMENT, value: e.checked });
              }}
            />
            <label htmlFor="patient-accept-privacy-policy" className="cursor-pointer p-checkbox-label ml-2">
              {Labels.LABEL_PAYMENT_CONDITIONS_1}{" "}
              <a href={UsloviPlacanja} target="_blank" rel="noreferrer">
                {Labels.LABEL_PAYMENT_CONDITIONS_2}
              </a>
            </label>
          </div>
          <div className="text-center mt-4">{Labels.LABEL_REQUIRED_FIELDS_NOTE}</div>
        </div>
        <Specialization />
      </div>
      <div className="wizard-footer flex flex-row justify-content-center py-4 w-full">
        <Button
          label={Labels.LABEL_ZAKAZI}
          onClick={() => {
            onPaymentSubmit(state?.appointment);
          }}
          className="border-radius-0"
          disabled={!state?.conditionsAgreement}
        />
      </div>
    </>
  );
}
