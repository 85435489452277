import { RefObject, useCallback, useRef } from "react";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import WizardLogical, { WizardLogicalType } from "../wizard/WizardLogical";
import AppointmentReservationCreateDto from "../../model/AppointmentReservationCreateDto";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import { useEffectOnce } from "../../infrastructure/Utils";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import AllSecurePaymentJsErrorsAttributes from "../../model/AllSecurePaymentJsErrorsAttributes";

export interface PaymentLogicalType {
  cardHolderRef: RefObject<any>;
  mmYYRef: RefObject<any>;
  onPaymentSubmit: (appointment: AppointmentReservationCreateDto) => void;
  ValidateExpiry: () => void;
  expiryField: (e: { target: any; keyCode: number }) => void;
  removeWarning: () => void;
}

export default function PaymentLogical(dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>, state: InitialStateType, sessionCodeRef: RefObject<string>) {
  const cardHolderRef = useRef<any>(null);
  const monthRef = useRef<any>(null);
  const yearRef = useRef<any>(null);
  const mmYYRef = useRef<any>(null);
  const tokenRef = useRef<string>("");
  const { reserveAppointment }: WizardLogicalType = WizardLogical();
  const payment = useRef<any>(null);

  useEffectOnce(() => {
    paymentIframesInit();
  });

  const paymentIframesInit = useCallback(() => {
    // @ts-ignore
    payment.current = new PaymentJs();
    payment.current.init(state?.publicIntegrationKey, "number_div", "cvv_div", function (payment: any) {
      payment.setNumberStyle({
        border: "1px solid transparent",
        width: "100%",
        "border-radius": "0px",
        padding: "12px 40px",
        height: "46px",
        outline: "none",
        top: "1px",
        position: "relative",
        "font-family": '\'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"\'',
        "font-size": "1rem",
        color: "#000000",
        "background-color": "transparent",
      });
      payment.setCvvStyle({
        border: "1px solid transparent",
        width: "100%",
        "border-radius": "0px",
        padding: "12px 40px",
        height: "46px",
        outline: "none",
        top: "1px",
        position: "relative",
        "font-family": '\'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"\'',
        "font-size": "1rem",
        color: "#000000",
        "background-color": "transparent",
      });
      payment.numberOn("focus", function () {
        removeWarning();
      });
      payment.numberOn("input", function (data: { cardType: string }) {
        let method = data.cardType;
        document.getElementById("card_type")!.classList.value = "card-type " + method;
      });
      payment.cvvOn("focus", function () {
        removeWarning();
      });
      // payment.setNumberPlaceholder(`${Labels.LABEL_CARD_NUMBER}`);
      // payment.setCvvPlaceholder(`${Labels.LABEL_CVC}`);
    });
    // eslint-disable-next-line
  }, []);

  const onPaymentSubmit = (appointment: AppointmentReservationCreateDto) => {
    let data = {
      card_holder: cardHolderRef.current?.value,
      month: monthRef.current?.value,
      year: yearRef.current?.value,
    };

    payment.current.tokenize(
      data,
      function (token: any, cardData: any) {
        tokenRef.current = token;
        dispatch({ type: actions.PAYMENT_PROCESSING, value: true });
        reserveAppointment(appointment, token, dispatch, sessionCodeRef.current!);
      },
      function (errors: Array<Object>) {
        //error callback function
        const string = JSON.stringify(errors);
        const objects = JSON.parse(string);
        objects.forEach(function (key: any, value: any) {
          if (key.attribute === AllSecurePaymentJsErrorsAttributes.CARD_HOLDER) {
            let cardHolderErrorMessage: string = Labels.LABEL_CARD_HOLDER_EMPTY_ERRROR;
            document.getElementById("card_holder_error")!.textContent += cardHolderErrorMessage;
            document.getElementById("error-card-holder")!.classList.value = "error visible";
          }
          if (key.attribute === AllSecurePaymentJsErrorsAttributes.CARD_NUMBER) {
            let cardErrrorMessage: string = key.key === "error.blank" ? Labels.LABEL_CARD_NUMBER_EMPTY_ERROR : Labels.LABEL_CARD_NUMBER_INVALID_ERROR;
            document.getElementById("card_number")!.textContent += cardErrrorMessage;
            document.getElementById("card_type")!.classList.value = "card-type false";
          }
          if (key.attribute === AllSecurePaymentJsErrorsAttributes.CARD_EXPIRATION_MONTH) {
            let expirationDateMonthError: string = key.key === "error.blank" ? Labels.LABEL_CARD_EXPIRATION_MONTH_EMPTY_ERROR : Labels.LABEL_CARD_EXPIRATION_MONTH_INVALID_ERROR;
            document.getElementById("card_month_year")!.textContent += expirationDateMonthError;
            document.getElementById("error-expiration")!.classList.value = "error visible";
          }
          if (key.attribute === AllSecurePaymentJsErrorsAttributes.CARD_EXPIRATION_YEAR) {
            let expirationDateYearError: string = key.key === "error.blank" ? Labels.LABEL_CARD_EXPIRATION_YEAR_EMPTY_ERROR : Labels.LABEL_CARD_EXPIRATION_YEAR_INVALID_ERROR;
            document.getElementById("card_month_year")!.textContent += expirationDateYearError;
            document.getElementById("error-expiration")!.classList.value = "error visible";
          }
          if (key.attribute === AllSecurePaymentJsErrorsAttributes.CARD_CVV) {
            let cardCvcError: string = key.key === "error.blank" ? Labels.LABEL_CARD_CVV_EMPTY_ERRROR : Labels.LABEL_CARD_CVV_INVALID_ERRROR;
            document.getElementById("card_cvc")!.textContent += cardCvcError;
            document.getElementById("error-cvc")!.classList.value = "error visible";
          }
        });
      }
    );
  };

  function ValidateExpiry() {
    monthRef.current = {};
    yearRef.current = {};

    let dateInput = mmYYRef?.current?.value;
    let expArray = dateInput?.split("/");
    let expmm = expArray && expArray[0] !== null ? expArray[0] : "";
    let expyy = expArray && expArray[1] !== null ? expArray[1] : "";
    let expyyyy = 20 + expyy;

    monthRef.current.value = expmm;
    yearRef.current.value = expyyyy;
  }

  function expiryField(e: { target: any; keyCode: number }) {
    let code = e.keyCode;
    let allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    e.target.value = e.target.value
      .replace(/^([1-9]\/|[2-9])$/g, "0$1/")
      .replace(/^(0[1-9]|1[0-2])$/g, "$1/")
      .replace(/^([0-1])([3-9])$/g, "0$1/$2")
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
      .replace(/^([0]+)\/|[0]+$/g, "0")
      // eslint-disable-next-line
      .replace(/[^\d\/]|^[\/]*$/g, "")
      .replace(/\/\//g, "/");
  }

  function removeWarning() {
    const form = document.getElementById("payment-form");
    const errors = form ? form.getElementsByClassName("p-error") : [];
    const errorIcons = form ? form.getElementsByClassName("error") : [];
    let i;
    for (i = 0; i < errors.length; i++) {
      errors[i].innerHTML = "";
      errors[i].classList.remove("false");
    }
    for (i = 0; i < errorIcons.length; i++) {
      errorIcons[i].classList.remove("visible");
      document.getElementById("card_type")!.classList.remove("false");
    }
  }

  return {
    cardHolderRef,
    mmYYRef,
    onPaymentSubmit,
    ValidateExpiry,
    expiryField,
    removeWarning,
  };
}
