import {Toast} from "primereact/toast";
import React, {useRef, useState} from "react";
import AuthData from "./AuthData";
import {MESSAGE_DURATION_IN_MILLISECONDS} from "./infrastructure/Utils";

interface AppContextProps {
  authData: AuthData;
  updateAuthData: (data: string) => void;
  deleteAuthData: () => void;
  showMessage: (summery: string, detail: string, severity?: string) => void;
  showBlockUI: boolean;
  setShowBlockUI: any;
  message: any;
}

export const AppContext = React.createContext({} as AppContextProps);

const Store = (props: any) => {
  const [authData, setAuthData] = useState(localStorage.getItem("nadjimed-auth") || "");
  const [showBlockUI, setShowBlockUI] = useState(false);
  const message = useRef<Toast>(null);

  const showMessage = (severity: string, summary: string, detail?: string) => {
    message?.current?.show({
      // @ts-ignore
      severity: severity,
      summary: summary,
      detail: detail ? detail : "",
      closable: true,
      life: MESSAGE_DURATION_IN_MILLISECONDS
    });
  };

  const setBlockUI = (value: boolean) => {
    if (value) {
      setShowBlockUI(value);
    } else {
      setTimeout(() => {
        setShowBlockUI(value);
      }, 500);
    }
  };

  const updateAuthData = (data: string) => {
    localStorage.setItem("nadjimed-auth", data);
    setAuthData(data);
  };

  const deleteAuthData = () => {
    setAuthData("");
    localStorage.removeItem("nadjimed-auth");
  };

  return (
    <AppContext.Provider
      value={{
        authData: authData !== "" ? (JSON.parse(authData) as AuthData) : new AuthData("", 0),
        updateAuthData,
        deleteAuthData,
        showMessage,
        showBlockUI: showBlockUI,
        setShowBlockUI: setBlockUI,
        message
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Store;
